import { tagService } from "../_services";
import { alertActions } from "./alert.actions";
import { tableActions } from "./table.actions";
export const tagActions = {
    deleteTag,
    saveTagUpdate
}

function saveTagUpdate(tag) {
    return dispatch => {
        tagService.updateTag(tag)
            .then(
                tag => {
                    dispatch(alertActions.success(`Successfully updated tag`));
                    dispatch(tableActions.updateInTable(tag))
                },
            )
    }
}

function deleteTag(id) {
    return dispatch => {
        tagService.deleteTag(id)
            .then(
                () => {
                    dispatch(alertActions.success(`Successfully deleted tag ${id}`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}