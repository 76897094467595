import { sectionsService } from '../_services';
import { alertActions } from "./alert.actions";
import { tableActions } from "./table.actions";

export const sectionsActions = {
    addSection,
    deleteSection,
    saveSectionUpdate
}

function addSection(section, isTemplate) {
    return dispatch => {
        sectionsService.addSection(section, isTemplate)
            .then(
                section => {
                    dispatch(tableActions.addToTable(section));
                    dispatch(alertActions.success(`Successfully added ${section.title}`));
                },
            );
    }
}
function deleteSection(id, isTemplate) {
    return dispatch => {
        sectionsService.deleteSection(id, isTemplate)
            .then(
                () => {
                    dispatch(alertActions.success(`Successfully deleted section ${id}`));
                },
            )
    }
}
function saveSectionUpdate(section, isTemplate) {
    console.log('SAVING', section)
    return dispatch => {
        sectionsService.updateSection(section, isTemplate)
            .then(
                () => {
                    dispatch(alertActions.success('Successfully updated'));

                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}