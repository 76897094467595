export const OrderType = {
    Sale: 0,
    Consignment: 1,
}

export const OrderStatus = {
    InBasket: 0,
    Requested: 1,
    Paid: 2,
    Consignment: 3,
    Declined: 4,
    Refunded: 5,
}

export const DeliveryStatus = {
    AwaitingPayment: 0,
    OutOfStock: 1,
    Requested: 2,
    Dispatched: 3,
    InTransit: 4,
    Delivered: 5,
    Returned: 6
}

