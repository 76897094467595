import { config } from '../_constants';
import { authHeader, handleResponse } from '../_store'

export const assetSourcesService = {
    searchAssetSources,
    deleteAssetSource,
    updateAssetSource,
    addAssetSource,
    getById
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${config.apiUrl}/asset-sources/${id}?include=logos`, requestOptions)
        .then(handleResponse)
}

function searchAssetSources(query, page, pageSize) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${config.apiUrl}/asset-sources/search?source=${query}&page=${page}&pagesize=${pageSize}`, requestOptions)
        .then(handleResponse)
        .then(sources => {
            return sources
        })
}

function deleteAssetSource(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }

    };
    return fetch(`${config.apiUrl}/asset-sources/${id}`, requestOptions)
        .then(handleResponse);
}
function addAssetSource(source) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(source)
    }
    return fetch(`${config.apiUrl}/asset-sources`, requestOptions)
        .then(handleResponse)
        .then(source => { return source })
}

function updateAssetSource(source) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(source)
    }
    return fetch(`${config.apiUrl}/asset-sources`, requestOptions)
        .then(handleResponse)
        .then(source => { return source })
}