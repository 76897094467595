import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import LoginErrorModal from '../../Components/Modals/Confirmation/LoginErrorModal';
import '../../Styles/_components/_login.scss';
import { tenantService } from '../../_services';
import ImageComponent from '../../Components/Layout/ImageComponent'
import { useTranslation } from 'react-i18next';
import { LanguageSelector } from 'src/Components/Layout/LanguageSelector';
import { NewPasswordForm } from '../PasswordReset/ResetPage'
import { ResetForm } from '../PasswordReset/PasswordReset';
import { LoginForm } from './LoginPage';
import { SignupForm } from './SignupPage';

export function LoginPage(props) {
    const { t } = useTranslation("common");


    const loginModal = useSelector(state => state.loginModal);
    const [subdomain, setSubdomain] = useState(undefined);
    const [assetLogo, setAssetLogo] = useState(undefined);
    const [assetBackground, setAssetBackground] = useState({});
    const [section, setSection] = useState(props.section ?? "login");

    useEffect(() => {
        let address = window.location.host;
        let domainParts = address.split(".");
        let sub = undefined;
        if (domainParts.length > 3) {
            sub = domainParts[0];
        }
        getLogo(sub)
    }, [])

    async function getLogo(sub) {
        const defualtLogo = '/parla-logo.png';
        const defualtBackground = '/parlar-admin-bg.jpg';
        if (sub) {
            setSubdomain(sub);
            const tenant = await tenantService.getHost(sub)
            tenant.small.content ? setAssetLogo(tenant.small.content) : setAssetLogo(defualtLogo)
            tenant.background.content ? setAssetBackground(tenant.background) : setAssetBackground({ content: defualtBackground, contentType: 'image/png' })
        } else {
            setAssetLogo(defualtLogo)
            setAssetBackground({ content: defualtBackground, contentType: 'image/png' })
        }
    }

    return (
        <div className="login-wrapper">
            <div className="login-page-asset">
                <ImageComponent asset={assetBackground} altText={t("login.company-background")} />
            </div>
            <div className="rhs">
                <div className='language'>
                    <LanguageSelector />
                </div>
                {assetLogo ? <img src={assetLogo} className="rhs-logo" /> : null}
                <SectionForm />
                <div className="rhs-footer">{t("login.powered-by")} <img src="/parla-logo.png" className="footer-logo" /></div>
            </div>
            {loginModal.isOpen ? (<LoginErrorModal />) : null}
        </div >
    );
    function SectionForm() {
        switch (section) {
            case "login": return <LoginForm subdomain={subdomain} setSection={setSection} />;
            case "reset": return <ResetForm subdomain={subdomain} setSection={setSection} />;
            case "password": return <NewPasswordForm subdomain={subdomain} setSection={setSection} />;
            case "signup": return <SignupForm subdomain={subdomain} setSection={setSection} />;
        }
    }
}