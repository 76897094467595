import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Title(props) {
    const { t } = useTranslation("common");
    return (
        <div>
            <div className="dashboard-title mb-0 align-self-start">{props.children}</div>
            <div className='dashboard-header-subtitle'>
                {t("dashboard.stores-update")}
            </div>
        </div>
    )
}