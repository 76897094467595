import { modalConstants } from "../_constants";

const initialState = {
    isOpen: false,
};

export function loginModal(state = initialState, action) {
    switch (action.type) {
        case modalConstants.OPEN_LOGIN_MODAL:
            return {
                ...state,
                isOpen: true
            };
        case modalConstants.CLOSE_LOGIN_MODAL:
            return {
                ...state,
                isOpen: false
            };

        default: return state;
    }
}