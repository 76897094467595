import { orderService } from "../_services/order.service";
import { alertActions } from "./alert.actions";
import { tableActions } from "./table.actions";

export const orderActions = {
    addOrder,
    saveOrderUpdate,
    deleteOrder,
    takeOwnership
}

async function takeOwnership(orderId) {
    const order = await orderService.getById(orderId);
    const updateOrder = { oldSessionId: order.sessionToken, orderId: orderId };
    await orderService.linkOrderToSession(updateOrder);
}

function addOrder(order) {
    return dispatch => {
        orderService.addOrder(order)
            .then(
                newOrder => {
                    const order = { ...newOrder, tenant: newOrder.tenant.name }
                    dispatch(tableActions.addToTable(order));
                    dispatch(alertActions.success(`Order #${newOrder.id} successfully created`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}

function deleteOrder(order) {
    return dispatch => {
        orderService.deleteOrder(order)
            .then(
                () => {
                    dispatch(alertActions.success(`Successfully deleted order #${order.id}`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}

function saveOrderUpdate(order) {
    return dispatch => {
        orderService.saveOrderUpdate(order)
            .then(
                order => {
                    dispatch(alertActions.success(`Successfully updated Order #${order.id}`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}