import { brandService, productsService, productTypeService } from '../_services';
import { apiService } from "../_services/api.service";
import { alertActions } from "./alert.actions";
import { loadingActions } from "./loading.actions";
import { tableActions } from "./table.actions";

export const productsActions = {
    addProduct,
    deleteProduct,
    saveProductUpdate,
    addInventory,
    saveInventoryUpdate,
    removeInventory
}

function addProduct(product) {
    return async dispatch => {
        let newProduct = { ...product }
        if (product.createBrand) {
            const createdBrand = await brandService.addBrand({ name: product.brandId });
            newProduct = { ...newProduct, brandId: createdBrand.id }
        }
        if (!product.productTypeId || product.productTypeId == 'default') {
            const defaultTypeSearch = await apiService.getItems('product-types', { searchField: 'name', searchValue: 'default' });
            const createdDefaultProductType = defaultTypeSearch.items.length ? defaultTypeSearch.items[0] : await productTypeService.addProductType({ name: 'default' })
            newProduct = { ...newProduct, productTypeId: createdDefaultProductType.id }
        }
        delete newProduct.createBrand;
        return productsService.addProduct(newProduct)
            .then(
                product => {
                    dispatch(tableActions.addToTable(product));
                    dispatch(alertActions.success(`Successfully added ${product.name}`));
                    return product
                },
                error => {
                    dispatch(alertActions.error('Unable to add product. ' + error.toString()));
                }
            );
    }
}
function deleteProduct(id) {
    return dispatch => {
        productsService.deleteProduct(id)
            .then(
                () => {
                    dispatch(alertActions.success(`Successfully deleted product ${id}`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}
function saveProductUpdate(product) {
    return dispatch => {
        productsService.updateProduct(product)
            .then(
                product => {
                    dispatch(alertActions.success(`Successfully updated ${product.name}`));

                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                },
                dispatch(loadingActions.setLoading(false))
            )
    }
}

function saveInventoryUpdate(inventory) {
    return dispatch => {
        productsService.updateInventory(inventory)
            .then(
                () => {
                    dispatch(alertActions.success(`Successfully updated inventory`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                },
                dispatch(loadingActions.setLoading(false))
            )
    }
}
function addInventory(inventory) {
    return dispatch => {
        productsService.addInventory(inventory)
            .then(
                () => {
                    dispatch(alertActions.success(`Successfully added inventory`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                },
                dispatch(loadingActions.setLoading(false))
            )
    }
}
function removeInventory(inventory) {
    return dispatch => {
        productsService.deleteInventory(inventory)
            .then(
                () => {
                    dispatch(alertActions.success(`Successfully deleted inventory`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                },
                dispatch(loadingActions.setLoading(false))
            )
    }
}