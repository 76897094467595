import { alertConstants } from '../_constants';

export function alert(state = { queue: [] }, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        ...state, queue: [...state.queue, {
          type: 'alert-success',
          message: action.message
        }]
      };
    case alertConstants.ERROR:
      return {
        ...state, queue: [...state.queue, {
          type: 'alert-danger',
          message: action.message
        }]
      };
    case alertConstants.WARNING:
      return {
        ...state, queue: [...state.queue, {
          type: 'alert-warning',
          message: action.message
        }]
      };     
    case alertConstants.INFO:
    return {
      ...state, queue: [...state.queue, {
        type: 'alert-info',
        message: action.message
      }]
    };      
    case alertConstants.CLEAR:
      return {
        ...state,
        queue: state.queue.slice(1)
      };
    default:
      return state
  }
}