import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import '../../../Styles/dashboard.scss';
import { journeyService } from "../../../_services/journeys.service";
import { apiService } from "../../../_services/api.service";
import { formatDateAndTime, formatISOTime } from '../../../_helpers/utility';
import { AppointmentStatus } from '../../../_enums/AppointmentStatus';
import { useTranslation } from 'react-i18next';
import StatisticsCard from './StatisticsCard';
import { DateTime } from 'luxon';
import { tenantService } from '../../../_services/tenant.service';
import { Feature } from '../../../_types';

export default function TodaysAppointmentsCard() {
    const { t } = useTranslation("common");

    const [calls, setCalls] = useState([]);
    const [departments, setDepartments] = useState({});
    const [view, setView] = useState('upcoming');
    const currentUserId = useSelector(state => state.authentication.user.id);
    const [features, setFeatures] = useState([]);

    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 7);
    const startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        today.getHours()
    );
    const endDate = new Date(
        tomorrow.getFullYear(),
        tomorrow.getMonth(),
        tomorrow.getDate()
    );
    useEffect(() => {
        const options = { searchFields: ['start>', 'end<', 'staff.userId'], searchValues: [startDate.toISOString(), endDate.toISOString(), currentUserId], sortBy: 'start', pageSize: 10 }
        journeyService.getAppointments(options).then(calls => {
            setCalls(groupCalls(calls.items));
            fetchDepartments(calls.items);
            tenantService.getFeatures().then(setFeatures);
        })
    }, [])

    function groupCalls(calls) {
        const result = {};
        for (const call of calls) {

            var range = getRelativeExpressions(call.start)

            if (!result[range]) {
                result[range] = [];
            }
            result[range].push(call);
        }
        console.log("Group Result", result)
        return result;
    }

    async function fetchDepartments(calls) {
        const departmentIds = calls.map(call => call.departmentId).filter(id => id);
        const uniqueDepartmentIds = [...new Set(departmentIds)];
        const departmentData = {};

        for (let id of uniqueDepartmentIds) {
            const department = await apiService.getItem("users", currentUserId, "departments", id);
            departmentData[id] = department.name;
        }

        setDepartments(departmentData);
    }

    function getRelativeExpressions(date) {
        const today = DateTime.now().startOf('day');
        const targetDate = DateTime.fromISO(date);
        const diffDays = targetDate.diff(today, ['days', 'hours']).days;

        switch (diffDays) {
            case 0:
                return t("dashboard.today");
            case 1:
                return t("dashboard.tomorrow");
            default:
                return t("dashboard.in-days", { count: diffDays });
        }
    }

    function Call(props) {

        function formatTime(c) {
            console.log(c);
            if (new Date(c).getDate() == new Date().getDate()) {
                return formatISOTime(c);
            } else {
                return formatDateAndTime(c);
            }
        }

        function timeToAppointment(date) {
            const today = DateTime.now();
            const targetDate = DateTime.fromISO(date);
            const diff = targetDate.diff(today, ['days', 'hours', 'minutes', 'seconds']);
            if (diff.days == 0 && diff.hours == 0) {
                return t("dashboard.in-minutes", { count: diff.minutes })
            } else if (diff.days == 0) {
                return t("dashboard.in-hours", { count: diff.hours })
            } else {
                return t("dashboard.in-days", { count: diff.days });
            }
        }

        function getClass(appointment) {
            if (appointment.status === 1) {
                return 'appointment-inprogress'
            } else {
                return 'appointment-future'
            }
        }

        const { c } = props;
        return <div className={'appointment-booked-card ' + getClass(c)}>
            <div className='appointment-data'>
                <div className='appointment-details'>
                    <div className='appointment-booked-card-name'>{c.title ? c.title : 'No Name Room'}</div>
                    <div className='appointment-booked-card-date'>{c.start ? formatTime(c.start) : undefined}<span className='sep-inline'> - </span>{c.end ? formatISOTime(c.end) : undefined}</div>
                </div>
                <span className='time-remaining'><i className='fas fa-alarm-clock'></i>{timeToAppointment(c.start)}</span>
            </div>
            <div className='appointment-booked-card-department'>
                <div><i className="far fa-building"></i> {departments[c.departmentId] ? departments[c.departmentId] : 'Loading...'}</div>
                <div><i className="far fa-store-alt"></i>{c.videoCallId ? "Store: Virtual" : "Store: In-Store"}</div>
            </div>
        </div>
    }


    function Calls(props) {
        const [collapsedGroups, setCollapsedGroups] = useState([]);

        function toggleCollapse(group) {
            if (collapsedGroups.some(cg => cg === group))
                setCollapsedGroups([...collapsedGroups.filter(cg => cg !== group)])
            else
                setCollapsedGroups([...collapsedGroups, group])
        }

        function getGroupClass(group) {
            switch (group) {
                case 'Today': return 'today';
                default: return 'future'

            }
        }

        const { calls } = props;
        if (!calls) return null;
        return Object.keys(calls).map((callGroup, j) => {
            return <div key={j} className={'appointment-card-group ' + getGroupClass(callGroup)}>
                <div className='appointment-booked-card-day' onClick={() => toggleCollapse(callGroup)}>{callGroup}</div>
                {
                    collapsedGroups.some(cg => cg === callGroup) ? null :
                        calls[callGroup].map((c, i) => {
                            if (c.videoCallId) {
                                if (c.status === AppointmentStatus.Cancelled) {
                                    return (
                                        <div key={i} className='cancelled-appointment'>
                                            <a href={`show-and-sell-call/${c.videoCallId}`}  >
                                                <span className='cancel-line' /><Call c={c} />
                                            </a>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={i} className='appointment-card-wrap'>
                                            <a key={i} href={`show-and-sell-call/${c.videoCallId}`} >
                                                <Call c={c} />
                                            </a>
                                        </div>
                                    )
                                }
                            } else {
                                if (c.status === AppointmentStatus.Cancelled) {
                                    return (
                                        <div key={i} className='cancelled-appointment'>
                                            <span className='cancel-line' /><Call c={c} />
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={i} className='appointment-card-wrap'>
                                            <Call key={i} c={c} />
                                        </div>
                                    )
                                }
                            }
                        })}
            </div>
        })
    }

    return (
        <div className='card-holder h-100'>
            <Card className='dashboard-appointments-card h-100'>
                <div className='dashboard-card-sm-title'>
                    {features && features.includes(Feature.Appointments) ?
                        t("dashboard.upcoming-appointments")
                        : t("dashboard.calls") + " " + t("dashboard.statistics")}
                </div>
                <div className='dashboard-card-tags-wrap'>
                    {features && features.includes(Feature.Appointments) ?
                        <>
                            <div className={`dashboard-card-tag ${view === 'upcoming' ? 'active' : ''}`} onClick={() => setView('upcoming')}>
                                {t("dashboard.upcoming")}
                            </div>
                            <div className={`dashboard-card-tag ${view === 'statistics' ? 'active' : ''}`} onClick={() => setView('statistics')}>
                                {t("dashboard.statistics")}
                            </div>
                        </>
                        :
                        null
                    }
                </div>
                <Card.Body>
                    {features && features.includes(Feature.Appointments) ? (
                        view === 'upcoming' ? (
                            calls && Object.keys(calls).length ? (
                                <Calls calls={calls} className='appointments-calls' />
                            ) : (
                                <div>{t("dashboard.no-appointments-at-the-moment")}</div>
                            )
                        ) : (
                            <StatisticsCard calls={calls} />
                        )
                    ) : (
                        <StatisticsCard calls={calls} />
                    )}

                </Card.Body>
            </Card>
        </div>
    )
}
