import { tableService } from "../Components/Table/table.services"
import { tableConstants, pageToUrl } from "../_constants"
import { alertActions } from "./alert.actions"

export const tableActions = {
    changeTableOption,
    addToTable,
    removeFromTable,
    updateInTable,
    acknowledgeUpdate,
}

function changeTableOption(option) {
    return dispatch => {
        dispatch({
            type: tableConstants.CHANGE_TABLE_OPTION,
            option
        })
    }
}

function acknowledgeUpdate() {
    return dispatch => {
        dispatch({
            type: tableConstants.UPDATE_ACKNOWLEDGED,
        })
    }
}
function updateInTable(item) {
    return dispatch => {
        dispatch({ type: tableConstants.REMOVE_FROM_TABLE, item })
        dispatch({ type: tableConstants.ADD_TO_TABLE, item })
    }
}
function addToTable(item) {
    return dispatch => {
        dispatch({ type: tableConstants.ADD_TO_TABLE, item })
    }
}
function removeFromTable(item, tableSelector) {
    return dispatch => {
        tableService.deleteItem({ path: pageToUrl(tableSelector), id: item.id })
            .then(() => {
                dispatch(alertActions.success(`Successfully removed item ${item.id} from the ${tableSelector} table.`));
                dispatch({ type: tableConstants.REMOVE_FROM_TABLE, item })
            },
                () => {
                    dispatch(alertActions.error('Unable to delete this item'));
                }
            )
    }
}

