import { config } from '../_constants';
import { authHeader, handleResponse } from '../_store'
import { generateOptions } from './options';

export const sectionsService = {
    getSections,
    searchSectionTemplates,
    deleteSection,
    updateSection,
    setParentSection,
    addSection,
    getById
}


function getSections(page, pageSize, isTemplate) {
    const path = isTemplate ? 'section-templates' : 'sections';
    if (!page && !pageSize) {
        page = 1,
            pageSize = 100
    }
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${config.apiUrl}/${path}?page=${page}&pagesize=${pageSize}&include=background,photo`, requestOptions)
        .then(handleResponse)
        .then(sections => {
            return sections
        })
}

function getById(id, isTemplate, options) {
    const path = isTemplate ? 'section-templates' : 'sections';
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    let url = `${config.apiUrl}/${path}/${id}`;
    if (options) {
        url += generateOptions(url, options);
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
}

function searchSectionTemplates(query, page, pageSize) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${config.apiUrl}/section-templates/search?title=${query}&page=${page}&pagesize=${pageSize}&include=relatedAssets`, requestOptions)
        .then(handleResponse)
        .then(sections => {
            return sections
        })
}

function deleteSection(id, isTemplate) {
    const path = isTemplate ? 'section-templates' : 'sections';

    const requestOptions = {
        method: 'DELETE',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }

    };
    return fetch(`${config.apiUrl}/${path}/${id}`, requestOptions)
        .then(handleResponse);
}
function addSection(section, isTemplate) {
    const path = isTemplate ? 'section-templates' : 'sections';

    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(section)
    }
    return fetch(`${config.apiUrl}/${path}`, requestOptions)
        .then(handleResponse)
        .then(section => { return section })
}

function setParentSection(sectionId, parentId, priority) {
    return updateSection(
        {
            id: sectionId,
            parentId: parentId,
            priority: priority,
        }
    )
}
function updateSection(section, isTemplate) {
    const path = isTemplate ? 'section-templates' : 'sections'

    const requestOptions = {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(section)
    }
    return fetch(`${config.apiUrl}/${path}`, requestOptions)
        .then(handleResponse)
        .then(section => { return section })
}