import { combineReducers } from 'redux';
import { editModal } from './editModal.reducer';
import { authentication } from './authentication.reducer';
import { alert } from './alert.reducer';
import { addModal } from './addModal.reducer';
import { table } from './table.reducer';
import { dataView } from './dataView.reducer';
import { loading } from './loading.reducer';
import { fileProgressReducer } from './uploadFile.reducer';
import { confirmationModal } from './confirmationModal.reducer';
import { loginModal } from './loginModal.reducer';
import { audits } from './audits.reducer';
import { breadcrumbs } from './breadcrumbs.reducer';
import { embeddedModal } from './embeddedModal.reducer';
import { helpPanel } from './helpPanel.reducer';
import { csvUploadReducer } from './csvUpload.reducer';
import { twilio } from './twilio.reducer';
import { assets } from './assets.reducer';

const rootReducer = combineReducers({
  authentication,
  assets,
  alert,
  editModal,
  addModal,
  table,
  dataView,
  loading,
  fileProgressReducer,
  confirmationModal,
  loginModal,
  audits,
  breadcrumbs,
  embeddedModal,
  helpPanel,
  csvUploadReducer,
  twilio
});

export default rootReducer;