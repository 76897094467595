import { config } from '../_constants';
import { authHeader, handleResponse } from '../_store'
import { generateOptions } from './options';

export const analyticsService = {
    getAnalytics,
    searchAnalytics,
}


function getAnalytics(options) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    let url = `${config.apiUrl}/analytics`;
    if (options) {
        url += generateOptions(url, options);
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(analytics => {
            return analytics
        })
}


function searchAnalytics(fields, options) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    let url = `${config.apiUrl}/analytics/search?`;
    if (fields) {
        var field;
        for(field in fields) {
            url += `${field}=${fields[field]}&`;
        }
        url = url.slice(0, -1); 
    }
    if (options) {
        url += generateOptions(url, options);
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(analytics => {
            return analytics
        })
}