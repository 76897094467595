import { modalConstants } from "../_constants";

const initialState = {
  isOpen: false,
  modalObject: {}
};

export function editModal(state = initialState, action) {
  switch (action.type) {
    case modalConstants.OPEN_EDIT_MODAL:
      return {
        ...state,
        modalObject: action.modalObject,
        isOpen: true
      };
    case modalConstants.CLOSE_EDIT_MODAL:
      return {
        ...state,
        modalObject: {},
        isOpen: false
      };
    default: return state;
  }
}