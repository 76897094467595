import { dataViewConstants } from "../_constants/dataView.constants";

export function dataView(state = {}, action) {
    switch (action.type) {
        case dataViewConstants.VIEW_DATA:
            return {
                ...state,
                data: action.data
            };
        default: return state;
    }

}