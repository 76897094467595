import React, { useEffect } from 'react';
import '../Styles/index.scss';
import { config } from '../_constants';
import { userActions } from '../_actions';
import { useDispatch } from 'react-redux';
import { Router } from './Router';
import ErrorWrapper from './ErrorWrapper';
import { useTranslation } from 'react-i18next';

function App() {


    useEffect(() => {
        const parlaScript = document.getElementById('parla-script')
        if (!config.environment && !parlaScript) {
            const script = document.createElement('script');
            script.setAttribute("id", "parla-script")
            script.src = "https://plausible.apps.parla.tech/js/script.js";
            script.defer = true;
            script.dataset['domain'] = "admin.parla.tech"
            document.head.appendChild(script)
        }
    }, []);

    const dispatch = useDispatch();
    const params = new URLSearchParams(window.location.search);
    const token = params.get("prl-t");
    const { t } = useTranslation("common");

    let address = window.location.host;

    if (token) {
        params.delete("prl-t");
        let subdomain: string | undefined = undefined;

        let domainParts = address.split(".");

        if (domainParts.length > 3)
            subdomain = domainParts[0];

        userActions.loginWithToken(token, subdomain, window.location.pathname + "?" + params.toString())(dispatch);
        return <div className="app-wrapper">
            {config.environment ? <div className="env-banner">{config.environment}</div> : null}
            <div>{t("app.redirecting")}</div>
        </div>
    }

    return (
        <div className="app-wrapper">
            {config.environment ? <div className="env-banner">{config.environment}</div> : null}
            <Router />
        </div>
    );
}

export { App };