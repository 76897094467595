import React, { useState, useEffect, useRef, memo } from "react";
import { assetsService } from "../../_services/assets.service";
import { useTranslation } from "react-i18next";
import ImageComponent from './ImageComponent';

type MediaComponentProps = {
    width?: number,
    height: number,
    className?: string
}

type AssetComponentProps = MediaComponentProps & {
    assetId: number
}

export const AssetComponent = memo<AssetComponentProps>(function AssetComponent({ assetId, width, height, className }) {
    const { t } = useTranslation("common")
    const [asset, setAsset] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        console.log("Getting Asset...", assetId, asset);
        if (assetId) {
            assetsService.getAssetById(assetId).then(setAsset).catch(setError);
        }
    }, [assetId])

    if (!assetId || error)
        return <div>{t("layout.assetComponent.error")}...</div>
    if (!asset)
        return <div>{t("layout.assetComponent.loading")}...</div>
    return <ImageComponent asset={asset} width={width} height={height} className={className} />
})