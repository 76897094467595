export const modalConstants = {
    OPEN_ADD_MODAL: "OPEN_ADD_MODAL",
    CLOSE_ADD_MODAL: "CLOSE_ADD_MODAL",

    OPEN_EDIT_MODAL: "OPEN_EDIT_MODAL",
    CLOSE_EDIT_MODAL: "CLOSE_EDIT_MODAL",

    OPEN_CONFIRMATION_MODAL: "OPEN_CONFIRMATION_MODAL",
    CLOSE_CONFIRMATION_MODAL: "CLOSE_CONFIRMATION_MODAL",

    OPEN_LOGIN_MODAL: 'OPEN_LOGIN_MODAL',
    CLOSE_LOGIN_MODAL: 'CLOSE_LOGIN_MODAL',

    OPEN_EMBEDDED_MODAL: 'OPEN_EMBEDDED_MODAL',
    CLOSE_EMBEDDED_MODAL: 'CLOSE_EMBEDDED_MODAL'
}