import { accountService } from '../_services'
import { alertActions } from ".";
import { Action, UnknownAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../_store';
export const accountActions = {
    updateAccount
}

function updateAccount(account: any): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        accountService.updateAccount(account)
            .then(
                () => {
                    dispatch(alertActions.success(`Successfully updated account`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}