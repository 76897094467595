import { tableConstants } from "../_constants";

const initialState = { option: 'Dashboard', updates: { removed: [], added: []} };

export function table(state = initialState, action) {
    switch (action.type) {
        case tableConstants.CHANGE_TABLE_OPTION:
            return {
                ...state,
                option: action.option
            };
        case tableConstants.UPDATE_ACKNOWLEDGED:
            return {...state, stale:false, updates: { removed: [], added: []}}
        case tableConstants.REMOVE_FROM_TABLE:
            return {...state, stale:true, updates: {...state.updates, removed: [...state.updates.removed, action.item.id]}}
        case tableConstants.ADD_TO_TABLE:
            return {...state, stale:true, updates: {...state.updates, added: [...state.updates.added, action.item]}}

        default: return state;
    }
}