import { assetSourcesService } from '../_services';
import { alertActions } from "./alert.actions";
import { tableActions } from "./table.actions";

export const assetSourcesActions = {
    addAssetSource,
    deleteAssetSource,
    saveAssetSourceUpdate
}

function addAssetSource(source) {
    return dispatch => {
        source.source = source.source.trim();
        assetSourcesService.addAssetSource(source)
            .then(
                source => {
                    dispatch(tableActions.addToTable(source));
                    dispatch(alertActions.success(`Successfully added asset source ${source.source}`));
                },
            );
    }
}
function deleteAssetSource(id) {
    return dispatch => {
        assetSourcesService.deleteAssetSource(id)
            .then(
                () => {
                    dispatch(alertActions.success(`Successfully deleted asset source ${id}`));
                },
                error => {
                    console.log('error', error)
                    dispatch(alertActions.error('Unable to delete asset source. Asset source is already in use.'));
                }
            )
    }
}
function saveAssetSourceUpdate(source) {
    return dispatch => {
        assetSourcesService.updateAssetSource(source)
            .then(
                source => {
                    dispatch(alertActions.success(`Successfully updated ${source.name}`));
                },
            )
    }
}