import { loadingConstants } from "../_constants";

const initialState = {
    isLoading: false
};

export function loading(state = initialState, action) {
    switch (action.type) {
        case loadingConstants.SET_LOADING:
            return {
                ...state,
                isLoading: action.boolean
            };
        default: return state;
    }
}