import { config } from '../_constants';
import { authHeader, handleResponse } from '../_store';
import { generateOptions } from './options';

export const brandService = {
    searchBrands,
    addBrand,
    updateBrand,
    deleteBrand,
    getById
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${config.apiUrl}/brands/${id}/?include=logos`, requestOptions)
        .then(handleResponse)
        .then(brand => {
            return brand
        })
}



function searchBrands(options) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    var url = `${config.apiUrl}/brands`;
    if (options) {
        url += generateOptions(url, options);
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(brands => { return brands })
}

function addBrand(brand) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(brand)
    }
    return fetch(`${config.apiUrl}/brands`, requestOptions)
        .then(handleResponse)
        .then(brand => { return brand })

}

function updateBrand(brand) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(brand)
    }
    return fetch(`${config.apiUrl}/brands`, requestOptions)
        .then(handleResponse)
        .then(brand => { return brand })
}

function deleteBrand(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }

    };
    return fetch(`${config.apiUrl}/brands/${id}`, requestOptions)
        .then(handleResponse);
}