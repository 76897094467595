import { DataType, IFieldData } from "./ModalTypes";
import i18next from "i18next";

export function InputElement(props: { field: IFieldData, submitted: boolean, value: any, setValue: (val: any) => void }) {
    const { field, submitted } = props;
    if (field.element)
        return field.element({ value: props.value, onChange: props.setValue });
    const className = 'form-control' + (submitted && !props.value ? ' is-invalid' : '')

    switch (field.dataType) {
        case DataType.string: return <input
            placeholder={field.placeholder}
            type="text"
            value={props.value}
            onChange={(e) => props.setValue(e.target.value)}
            className={className}
            required
            pattern="\S+">
        </input>
        case DataType.boolean: return <select value={props.value} onChange={(e => props.setValue(e.target.value))} className={className}>
            <option value="false">{i18next.t("common:modals.false")}</option>
            <option value="true">{i18next.t("common:modals.true")}</option>
        </select>
        case DataType.selection: return <select value={props.value} onChange={(e => props.setValue(e.target.value))} className={className}>
            {field.choices && Object.keys(field.choices).map(c => <option value={c}>{field.choices![c]}</option>)}
        </select>
        case DataType.range: return <input type='range' min={field.min} max={field.max} value={props.value} onChange={(e => props.setValue(e.target.value))}
            className={className + " slider col-12"}>
        </input>
        case DataType.radio: return <input name={field.name as string} className={className + " form-check-input radio-input"} type="radio" value={props.value} checked={props.value ? true : false}
            onChange={(e => props.setValue(e.target.checked ? field.name : undefined))} />
    }
}