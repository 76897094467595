import { modalConstants } from "../_constants"

export const confirmationModalActions = {
    openModal,
    closeModal
}
function openModal(modalObject) {
    return dispatch => {
        dispatch({
            type: modalConstants.OPEN_CONFIRMATION_MODAL,
            modalObject
        })
    }
}

function closeModal() {
    return dispatch => {
        dispatch({
            type: modalConstants.CLOSE_CONFIRMATION_MODAL,
            isOpen: false
        })
    }
}