import { config } from '../_constants';
import { authHeader, handleResponse } from '../_store'
import axios from 'axios';
import { generateOptions } from './options';

export const assetsService = {
    searchAssets,
    deleteAsset,
    updateAsset,
    addAsset,
    getAssetById,
    uploadFile
}

function getAssetById(id, includes) {
    const includeList = includes ? `include=${includes}` : '';
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${config.apiUrl}/assets/${id}/?${includeList}`, requestOptions)
        .then(response => handleResponse(response,true))
}

function uploadFile(file, onProgress) {
    const formPayload = new FormData()
    formPayload.append('file', file.file)
    return axios.post(
        '/assets/upload',
        formPayload,
        {
            baseURL: `${config.apiUrl}`,
            url: '/assets/upload',
            method: 'POST',
            headers: { ...authHeader() },
            data: formPayload,
            onUploadProgress: progress => {
                const { loaded, total } = progress
                const percentageProgress = Math.floor((loaded / total) * 100)
                if (onProgress)
                    onProgress(file.id, percentageProgress);
            }
        },
    ).then(axiosResponse => { return axiosResponse.data; }).then(asset => { return asset });
}

function searchAssets(options) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    var url = `${config.apiUrl}/assets`;
    if (options) {
        url += generateOptions(url, options);
    }

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(assets => {
            return assets
        })
}

function deleteAsset(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }

    };
    return fetch(`${config.apiUrl}/assets/${id}`, requestOptions)
        .then(handleResponse);
}
function addAsset(asset, resave) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(asset)
    }
    if (resave)
        return fetch(`${config.apiUrl}/assets/resave`, requestOptions).then(handleResponse)
    else
        return fetch(`${config.apiUrl}/assets`, requestOptions).then(handleResponse)
}

function updateAsset(asset) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(asset)
    }
    return fetch(`${config.apiUrl}/assets`, requestOptions)
        .then(handleResponse)
        .then(asset => { return asset })
}