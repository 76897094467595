// There's a link between page and url in the help system which might break if these values change

export const page = {
    Dashboard: 'Dashboard',
    Tenant: 'Company',
    Tenants: 'Switch Company',
    SectionTemplate: 'Section Templates',
    Product: 'Products',
    Media: 'Media',
    User: 'User Management',
    Journey: 'Journeys',
    Q: 'Product Detail',
    Brand: 'Brands',
    AssetSource: 'Asset-Sources',
    Fulfilment: 'Fulfillment',
    Customer: 'CRM',
    ProductType: 'Product-Type',
    VisitsHistory: 'Boards',
    CallsHistory: 'Visits',
    PurchaseHistory: 'Orders',
    SMS: 'Messages',
    Notes: 'Notes',
    JourneyMessages: 'Journey Messages',
    AddressBook: 'Address Book',
    Settings: 'Settings',
    Calls: 'Calls',
    Messages: 'Messages',
    Reports: 'Reports',
    ReportTemplates: 'ReportTemplates',
    LivestreamCalls: 'Livestream',
    OneToOneCalls: 'One To One',
    ShowAndSellCalls: 'Show And Sell',
    Search: 'Search',
    Tags: 'Tags',
    Orders: 'Orders',
    Shop: 'Shop',
    Services: 'Services',
    Integrations: 'Integrations',
    Account: 'Account',
    Terms: 'Terms and Conditions',
    EmailTemplates: 'EmailTemplates',
    Consignment: 'Consignments',
    Audit: 'Auditing',
    Appointments: 'Appointments',
    CTA: 'CTA',
}

export function getTitle(pageName) {
    switch (pageName) {
        case page.Journey: return "page.journey";
        case page.Customer: return "page.customer";
        case page.User: return "page.users";
        case page.Product: return "page.product";
        case page.AssetSource: return "page.asset-source";
        case page.Orders: return "page.order";
        case page.Consignment: return "page.consignment";
        case page.SectionTemplate: return "page.section-template";
        case page.Dashboard: return "page.dashboard";
        case page.Tenant: return "page.tenant";
        case page.Tenants: return "page.tenants";
        case page.Media: return "page.media";
        case page.Brand: return "page.brand";
        case page.Fulfilment: return "page.fulfillment";
        case page.ProductType: return "page.productType";
        case page.VisitsHistory: return "page.visits-history";
        case page.CallsHistory: return "page.calls-history";
        case page.PurchaseHistory: return "page.purchase-history";
        case page.SMS: return "page.sms";
        case page.Notes: return "page.notes";
        case page.JourneyMessages: return "page.journey-messages";
        case page.AddressBook: return "page.address-book";
        case page.Settings: return "page.settings";
        case page.Calls: return "page.calls";
        case page.Messages: return "page.messages";
        case page.Reports: return "page.reports";
        case page.LivestreamCalls: return "page.live-stream-calls";
        case page.OneToOneCalls: return "page.one-to-one-calls";
        case page.ShowAndSellCalls: return "page.show-and-sell-calls";
        case page.Search: return "page.search";
        case page.Tags: return "page.tags";
        case page.Shop: return "page.shop";
        case page.Services: return "page.services";
        case page.Integrations: return "page.integrations";
        case page.Account: return "page.account";
        case page.Terms: return "page.terms";
        case page.EmailTemplates: return "page.emailTemplates";
        case page.Audit: return "page.audit";
        case page.Appointments: return "page.appointments";
        case page.ReportTemplates: return "page.report-templates";
        case page.CTA: return "page.cta";
        default: return `page.${pageName}`;
    }
}
export function getSubTitle(pageName) {
    switch (pageName) {
        case page.Tenant: return "page.subtitle.tenant";
        case page.Shop: return "page.subtitle.shop";
        case page.User: return "page.subtitle.user";
        case page.Audit: return "page.subtitle.audit";
        case page.Reports: return "page.subtitle.reports";
        case page.Tenants: return "page.subtitle.tenants";
        case page.Orders: return "page.subtitle.orders";
        case page.Journey: return "page.subtitle.journey";
        case page.Brand: return "page.subtitle.brand";
        case page.SectionTemplate: return "page.subtitle.sectionTemplate";
        case page.Tags: return "page.subtitle.tags";
        case page.Product: return "page.subtitle.product";
        case page.ProductType: return "page.subtitle.productType";
        case page.Media: return "page.subtitle.media";
        case page.AssetSource: return "page.subtitle.assetSource";
        case page.Fulfilment: return "page.subtitle.fulfillment";
        case page.Customer: return "page.subtitle.customer";
        case page.Consignment: return "page.subtitle.consignment";
        case page.Services: return "page.subtitle.services";
        case page.CTA: return "page.subtitle.cta";
        default: return `page.subtitle.${pageName}`;
    }
}

//Page to API controller path
export function pageToUrl(pageName) {
    switch (pageName) {
        case page.Appointments: return 'appointment';
        case page.SectionTemplate: return 'section-templates';
        case page.Customer: return 'customers';
        case page.ProductType: return 'product-types';
        case page.Product: return 'products';
        case page.Fulfilment: return 'fulfilment';
        case page.AssetSource: return 'asset-sources';
        case page.User: return 'users';
        case page.Brand: return 'brands';
        case page.Media: return 'assets';
        case page.Search: return 'search';
        case page.Reports: return 'reports';
        case page.ReportTemplates: return 'reports/templates';
        case page.ShowAndSellCalls:
        case page.OneToOneCalls:
        case page.LivestreamCalls: return 'video-calls';
        case page.Journey: return 'journey';
        case page.Consignment:
        case page.Orders: return 'orders';
        case page.Messages: return 'messages';
        case page.Shop: return 'shops';
        case page.Services: return 'appointmentTemplates';
        case page.Integrations: return 'integrations';
        case page.Account: return 'account';
        case page.Tenant: return 'tenant';
        case page.Tags: return 'tags';
        case page.EmailTemplates: return 'email-templates';
        case page.CTA: return 'cta';
        default: return pageName;
    }
}

//Page to AdminPanel Path
export function pageToPath(page) {
    const formatedPathName = page.replace(/\s+/g, '-').toLowerCase();
    return `/${formatedPathName}`;
}