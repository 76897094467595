import { config } from '../_constants';
import { authHeader, handleResponse } from '../_store';

export const auditService = {
    getAudits
}

function getAudits(page, perPage) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }
    }
    return fetch(`${config.apiUrl}/audit?sortby=time&sortdirection=Descending&pageSize=${perPage}&page=${page}`, requestOptions)
        .then(handleResponse)        
}