import CSVUploadConstants from '../_constants/csvUpload.constants';

const INITIAL_STATE = {
    uploadProgress: {
    },
}

export function csvUploadReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CSVUploadConstants.CSV_COMPLETED_UPLOAD:
            return {
                ...state,
                uploadProgress: {
                }
            }

        case CSVUploadConstants.SET_CSV_UPLOAD_PROGRESS:
            return {
                ...state,
                uploadProgress: {
                    ...state.uploadProgress,
                    progress: action.payload.progress
                },
            }

        case CSVUploadConstants.CSV_SUCCESS_UPLOAD:
            return {
                ...state,
                uploadProgress: {
                    ...state.uploadProgress,
                    status: 1,
                },
            }

        case CSVUploadConstants.CSV_FAILURE_UPLOAD:
            return {
                ...state,
                uploadProgress: {
                    ...state.uploadProgress,
                    status: 2,
                },
            }
        default:
            return state
    }
}

export default csvUploadReducer