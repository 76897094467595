import React from 'react'

export const NavBarToggle = React.forwardRef(({ children, onClick, extraClassName }, ref) => {
  function getClassName() {
    if (!extraClassName) {
      return "nav-link";
    }
    return 'nav-link ' + extraClassName;
  }
  return <a className={getClassName()} href="#" ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>
    {children}
  </a>
}
);