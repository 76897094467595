import { modalConstants } from "../_constants"

export const addModalActions = {
    openModal,
    closeModal
}
function openModal(modalReference) {
    return dispatch => {
        dispatch({
            type: modalConstants.OPEN_ADD_MODAL,
            modalReference: modalReference
        })
    }
}

function closeModal() {
    return dispatch => {
        dispatch({
            type: modalConstants.CLOSE_ADD_MODAL,
            isOpen: false
        })
    }
}