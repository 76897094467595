import { page, pageToUrl } from "../_constants"
import { apiService } from "./api.service"

export const shopLocationService = {
    addShopLocation,
    saveShopLocationUpdate,
    deleteShopLocation,
    getByid,
    getShopLocations
}

const url = pageToUrl(page.Shop)

function addShopLocation(shop) {
    return apiService.addItem(url, shop).then(shop => shop)
}
function saveShopLocationUpdate(shop) {
    return apiService.updateItem(url, shop).then(shop => shop)
}
function deleteShopLocation(shop) {
    return apiService.deleteItem(url, shop.id).then(shop => shop)
}
function getByid(id, options) {
    return apiService.getItem(url, id, options).then(shop => shop)
}
function getShopLocations(options) {
    return apiService.getItems(url, options).then(shop => shop)
}