import { loadingConstants } from "../_constants/loading.constants"

export const loadingActions = {
    setLoading
}

function setLoading(boolean) {
    return dispatch => {
        dispatch(toggle(boolean))
    }
}

function toggle(boolean) { return { type: loadingConstants.SET_LOADING, boolean } }