import { modalConstants } from "../_constants";

const initialState = {
    isOpen: false,
    modalObject: {}
};

export function addModal(state = initialState, action) {
    switch (action.type) {
        case modalConstants.OPEN_ADD_MODAL:
            return {
                ...state,
                isOpen: true,
                modalReference: action.modalReference
            };
        case modalConstants.CLOSE_ADD_MODAL:
            return {
                ...state,
                isOpen: false
            };

        default: return state;
    }
}