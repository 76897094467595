import { assetSourcesService, assetsService } from '../_services';
import { alertActions } from "./alert.actions";
import { tableActions } from "./table.actions";
import { failureUploadFile, successUploadFile, setUploadProgress } from "./uploadFile.actions";
import { assetsConstants } from "../_constants";

export const assetsActions = {
    addAsset,
    deleteAsset,
    saveAssetUpdate,
    updateAssetImage,
    uploadCreateAsset,
    clearAsset
}

function clearAsset() {
    return dispatch => {
        dispatch(clear());
    };
}

function addAsset(asset, modalReference, resave) {
    return async dispatch => {
        let newAsset = { ...asset };
        if (asset?.createSource) {
            const createdSource = await assetSourcesService.addAssetSource({ source: asset.sourceId });
            newAsset = { ...newAsset, sourceId: createdSource.id }
        }

        newAsset = Object.keys(newAsset)
            .filter(key => key !== 'createSource').reduce((obj, key) => { obj[key] = newAsset[key]; return obj }, {});
        assetsService.addAsset(newAsset, resave)
            .then(
                asset => {
                    dispatch(addAssetSuccess(asset, modalReference));
                    dispatch(tableActions.addToTable(asset));
                    dispatch(alertActions.success(`Successfully added asset `, asset.id));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }
}

function uploadCreateAsset(files, modalReference) {
    return dispatch => {
        const onProgress = (id, progress) => dispatch(setUploadProgress(id, progress));
        if (files && files.length) {
            files.forEach(file => {
                assetsService.uploadFile(file, onProgress)
                    .then(
                        returnedAsset => {
                            returnedAsset.author = file.asset.author;
                            returnedAsset.tenantId = file.asset.tenantId;
                            returnedAsset.sourceId = file.asset.sourceId;
                            returnedAsset.tags = file.asset.tags;
                            returnedAsset.centreX = 50;
                            returnedAsset.centreY = 50;
                            returnedAsset.type = file.asset.type
                            assetsService.updateAsset(returnedAsset)
                                .then(polishedAsset => {
                                    dispatch(successUploadFile(file.id));
                                    dispatch(addAssetSuccess(polishedAsset, modalReference));
                                    dispatch(tableActions.addToTable(polishedAsset));
                                });
                        },
                        error => {
                            dispatch(failureUploadFile(file.id));
                            dispatch(alertActions.error(error.toString()));
                        }
                    )
            })
        }
    }
}

function deleteAsset(id) {
    return dispatch => {
        assetsService.deleteAsset(id)
            .then(
                () => {
                    tableActions.removeFromTable(id);
                    dispatch(alertActions.success(`Successfully deleted asset ${id}`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}
function saveAssetUpdate(asset) {
    return dispatch => {
        assetsService.updateAsset(asset)
            .then(
                () => {
                    dispatch(alertActions.success(`Successfully updated ${asset.id}`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}

function updateAssetImage(file, asset) {
    return dispatch => {
        assetsService.updateImage(file, asset)
            .then(
                () => {
                    dispatch(alertActions.success('Successfully uploaded image.'));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}

function addAssetSuccess(asset, modalReference) { return { type: assetsConstants.ADD_ASSETS_SUCCESS, asset, modalReference } }
function clear() { return { type: assetsConstants.CLEAR_ASSET } }
