import { config } from '../_constants';
import { authHeader, handleResponse } from '../_store'
import { sectionsService } from './sections.service';
import 'regenerator-runtime'
import { apiService } from './api.service';

export const journeyService = {
    getJourneys,
    searchJourneys,
    deleteJourney,
    updateJourney,
    addJourney,
    requestAccessCode,
    deleteAccessCode,
    getAccessCodes,
    getJourneyById,
    emailAccessCode,
    getAppointments
}


function getJourneys(page, pageSize) {
    if (!page && !pageSize) {
        page = 1,
            pageSize = 100
    }
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${config.apiUrl}/journey?page=${page}&pageSize=${pageSize}&include=tenant`, requestOptions)
        .then(handleResponse)
}

function getJourneyById(id, options) {
    return apiService.getItem('journey', id, options)
}

function requestAccessCode(params, id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(params)
    };

    const errorOptions = { 409: "Access Code already in use" };

    return fetch(`${config.apiUrl}/journey/${id}/access-code`, requestOptions)
        .then((response, errorHandled) => handleResponse(response, errorHandled, errorOptions))
        .then(accessCode => {
            return accessCode
        })
}

function emailAccessCode(id, accessCodeId) {
    return apiService.addItem(`journey/${id}/access-code/${accessCodeId}/email`, {});
}

function getAccessCodes(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${config.apiUrl}/journey/${id}?include=accessCodes`, requestOptions)
        .then(handleResponse)
        .then(journeys => {
            return journeys
        })
}

function deleteAccessCode(id, acId) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${config.apiUrl}/journey/${id}/access-code/${acId}`, requestOptions)
        .then(handleResponse)
}

function searchJourneys(options) {
    return apiService.getItems('journey', options).then(journeys => journeys);
}

function deleteJourney(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }

    };
    return fetch(`${config.apiUrl}/journey/${id}`, requestOptions)
        .then(handleResponse);
}
async function addParentSectionToJourney(journey) {
    let section = {
        title: `${journey.name} - Section Group`,
        tenantId: journey.tenantId
    }
    const parentSection = await sectionsService.addSection(section)
    journey = { ...journey, sectionId: parentSection.id }
    return journey;

}

async function addJourney(journey) {
    const journeyWithSection = await addParentSectionToJourney(journey)

    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(journeyWithSection)
    }
    return fetch(`${config.apiUrl}/journey`, requestOptions)
        .then(handleResponse)
        .then(journey => { return journey })
}

function updateJourney(journey) {
    const accessCodes = journey.accessCodes;
    delete journey.accessCodes;
    const requestOptions = {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(journey)
    }
    return fetch(`${config.apiUrl}/journey`, requestOptions)
        .then(handleResponse)
        .then((savedJourney) => ({ ...savedJourney, accessCodes }))
}


function getAppointments(options) {
    return apiService.getItems('appointment', options)
}

export const RoomType = {
    Livestream: 0,
    OneToOne: 1,
    ShowAndSell: 2
}