export const modifyFiles = (existingFiles, payload) => {
  let fileToUpload = {};
  const { files, asset } = payload;
  let oldTotal = 0;
  if(existingFiles.count) {
    oldTotal = existingFiles.count
  }
  for (let i = 0; i < files.length; i++) {
    const id = oldTotal + i + 1
    fileToUpload = {
      ...fileToUpload,
      [id]: {
        id,
        file: files[i],
        progress: 0,
        asset: asset,
      },
      count: oldTotal+files.length,
    }
  }

  return fileToUpload
}

export const removeFile = (existingFiles, id) => {
    const { [id]: removedValue, ...everythingElse } = existingFiles;
    console.log("Upload complete", removedValue)
    everythingElse.count = everythingElse.count - 1;
    return everythingElse;
}