import { modalConstants } from "../_constants";

const initialState = {
    isOpen: false,
    modalObject: {}
};

export function confirmationModal(state = initialState, action) {
    switch (action.type) {
        case modalConstants.OPEN_CONFIRMATION_MODAL:
            return {
                ...state,
                modalObject: action.modalObject,
                isOpen: true
            };
        case modalConstants.CLOSE_CONFIRMATION_MODAL:
            return {
                ...state,
                modalObject: {},
                isOpen: false
            };

        default: return state;
    }
}