import { tableConstants } from "../_constants";
import { journeyService, sectionsService } from '../_services'
import { twilioService } from "../_services/twilio.service";
import { alertActions } from "./alert.actions";
import { tableActions } from "./table.actions";

export const journeyActions = {
    addJourney,
    deleteJourney,
    saveJourneyUpdate,
    copyJourney
}

function addJourney(journey) {
    return dispatch => {
        journeyService.addJourney(journey)
            .then(
                async returnedJourney => {
                    //NFC Journey type
                    // const tenant = await tenantService.getTenantById(journey.tenantId, "activeIntegrations");

                    // for (var tenantIntegration of tenant.activeIntegrations) {
                    //     if (tenantIntegration.class == journey.customType) {
                    //         journeyIntegration.createConfig(returnedJourney.id, tenantIntegration.class, { active: true });
                    //     }
                    // }
                    dispatch(tableActions.addToTable(returnedJourney));
                    dispatch(alertActions.success(`Successfully added journey ${returnedJourney.name}`));
                },
                () => {
                    dispatch(alertActions.error(`Error creating journey`));
                }
            );
    }
}

async function copySection(sectionId, parentId) {
    let section = await sectionsService.getById(sectionId, false, {include: ['products', 'relatedAssets','sections']});
    delete section.id;
    delete section.background;
    section.parentId = parentId;
    for (var product of section.products) {
        delete product.sectionId;
        delete product.section;
    }
    for (var relatedAsset of section.relatedAssets) {
        delete relatedAsset.asset;
    }
    let childIds = section.sections.map(s => s.id);
    delete section.sections;
    let newSection = await sectionsService.addSection(section, false);
    for (const id of childIds) {
        await copySection(id, newSection.id);
    };
}

function copyJourney(journey) {
    return async (dispatch) => {
        try {
            let journeyCopy = { ...journey, name: journey.name + ' (duplicate)' };
            delete journeyCopy.id;
            delete journeyCopy.tenant;
            delete journeyCopy.owner;
            delete journeyCopy.section;
            let groupSectionId = journeyCopy.sectionId;
            delete journeyCopy.sectionId;
            let newJourney = await journeyService.addJourney(journeyCopy);
            let section = await sectionsService.getById(groupSectionId, false, { include: ['sections'] });
            for (const childSection of section.sections) {
                await copySection(childSection.id, newJourney.sectionId);
            }
            dispatch(tableActions.addToTable(newJourney));
            dispatch(alertActions.success(`Successfully copied ${journey.name}`))
        } catch (error) {
            dispatch(alertActions.error(`Copy failed: {error}`));
        }
    }
}

function deleteJourney(journey) {
    return dispatch => {
        twilioService.removeJourneyConfig(journey.id).then(() => {
            journeyService.deleteJourney(journey.id)
                .then(
                    () => {
                        dispatch(alertActions.success(`Successfully deleted journey ${journey.id}`));
                        dispatch({ type: tableConstants.REMOVE_FROM_TABLE, item: journey })
                    },
                )
        });
    }
}
function saveJourneyUpdate(journey) {
    return dispatch => {
        journeyService.updateJourney(journey)
            .then(
                journey => {
                    dispatch(alertActions.success(`Successfully updated ${journey.name}`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}