import { authHeader } from "../../_store";
import { config } from "../../_constants";

export function apiConnect(code) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },        
    }
    return fetch(`${config.apiUrlThirdParty}/instagram/create${code}`, requestOptions)
}
