export const loggingService = {
    sendLogs,
}

function sendLogs(user, action, message) {
    const data = JSON.stringify({
      streams: [
        {
          stream: {
            system: "Admin Panel",  
            device: navigator.userAgent,
            userId: user.id.toString(),
            tenantId: user.activeTenant?.id.toString(),
            action
          },
          values: [
            [`${new Date().valueOf() * 1000000}`, message],
          ]
        }
      ]
    })
  
    const blob = new Blob([data], { type: 'application/json' });
    navigator.sendBeacon('https://logging.apps.parla.tech/loki/api/v1/push', blob);
  }