import { useState } from 'react'
import {  useNavigate } from 'react-router-dom';
import { config } from '../../_constants'
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import TenantsPage from '../../Pages/TenantsPage';
import { userService } from '../../_services';
import { apiConnect } from './instagram.service';


export function InstagramCallback() {
    let query = location.search;
    let [connected, setConnected] = useState(false);    
    const loggedIn = useSelector(state => state.authentication.loggedIn);
    const currentUser = useSelector(state => state.authentication.user);
    const navigate = useNavigate();
    let address = window.location.host;
    let domainParts = address.split(".");
    let subdomain = undefined;
    if (domainParts.length > 3)
        subdomain = domainParts[0];

    if (connected) {
        navigate(`/tenant`);
        return null;
    }

    //Read cookie for subdomain redirect
    let targetSubdomain = Cookies.get('subdomain');

    //if we hit here at admin.parla.tech and the subdomain isn't blank then redirect BEFORE continuing to ensure we get the correct state variable
    if (targetSubdomain != subdomain && targetSubdomain) {
        let newDest = "https://";
        newDest += `${targetSubdomain}.${config.admin}/instagram${query}`;
        console.log("Missing subdomain, re-routing to " + newDest);
        location.href = newDest;
        return <div />;
    }

    function rerouteToSubdomain(tenant) {
        userService.changeTenancy(tenant.id).then(u => {
            location.href = `https://${tenant.subdomain}.${config.admin}/instagram?prl-t=${u.token}&${window.location.search}`
        });
    }

    //forward query path to API
    //redirect user back to TenantEdit (Current Tenancy?) e.g. admin.parla.tech => local.admin.parla.tech
    if (!loggedIn) {
        //stuff the query in local storage and ask the user to signin
        localStorage.setItem("redirect", location);
        navigate(`/login`);
        return null;
    }

    if (subdomain) {        
        console.log("Connecting to API");
        apiConnect(window.location.search + "#_")
            .then(() => {
                console.log("Redirecting to company");                
                setConnected(true);
            });
    } else {
        if (!currentUser.activeTenant) {
            //Also check if tenant has already been selected (initiated from modist side)
            return <TenantsPage showCreateNew={false} tenantSelected={(t) => {
                console.log("Company selected, routing to new subdomain");
                rerouteToSubdomain(t);
            }} />
        } else {
            console.log("Routing to tenant subdomain");
            rerouteToSubdomain(currentUser.activeTenant);
        }
    }
    return <div className="instagram-processing"></div>
}