import { breadcrumbConstants } from "../_constants";

export function breadcrumbs(state = { sectionRoutes: [], parentJourney: null }, action) {
    switch (action.type) {

        case (breadcrumbConstants.SET_PARENT_JOURNEY):
            return {
                parentJourney: action.journey,
                sectionRoutes: []
            };

        case (breadcrumbConstants.UNSET_PARENT_JOURNEY):
            return state; // unsetting parent journey assumes breadcrumb experience to be reset

        case (breadcrumbConstants.SET_PREVIOUS_SECTION):
            state.sectionRoutes.push(action.section)
            return {
                ...state
            };

        case (breadcrumbConstants.UNSET_PREVIOUS_SECTION): {
            const index = state.sectionRoutes.findIndex(section => section.id === action.section.id);
            if (index > -1) {
                const newRoutesList = state.sectionRoutes.slice(0, index)
                return {
                    ...state,
                    sectionRoutes: newRoutesList
                };
            } else {
                return state;
            }
        }
        case (breadcrumbConstants.CLEAR_SECTIONS):
            return { ...state, sectionRoutes: [] }

        default:
            return state
    }

}