import { brandService } from "../_services/brands.service";
import { alertActions } from "./alert.actions";
import { tableActions } from "./table.actions";

export const brandActions = {
    addBrand,
    saveBrandUpdate,
    deleteBrand
}

function addBrand(brand) {
    return dispatch => {
        brandService.addBrand(brand)
            .then(
                brand => {
                    dispatch(tableActions.addToTable(brand));
                    dispatch(alertActions.success(`${brand.name} successfully created`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}

function deleteBrand(id) {
    return dispatch => {
        brandService.deleteBrand(id)
            .then(
                () => {
                    dispatch(alertActions.success(`Successfully deleted brand ${id}`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}

function saveBrandUpdate(brand) {
    return dispatch => {
        brandService.updateBrand(brand)
            .then(
                brand => {
                    dispatch(alertActions.success(`Successfully updated ${brand.name}`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}