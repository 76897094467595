import React, { Component, ErrorInfo, ReactNode, useState } from "react";
import './error.scss';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

function ErrorResponse(props: { error: Error | null; }) {
    const { t } = useTranslation("common");
    const [showData, setShowData] = useState(false);
    const [sendingData, setDataSending] = useState(false);
    const [dataSent, setDataSent] = useState(false);
    const { error } = props;
    const [date] = useState(new Date());
    const user = useSelector((state: { authentication: { user: Record<string, any> } }) => state.authentication.user);

    function sendLogs() {
        setDataSending(true);

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Scope-OrgID': 'Parla'
            },
            body: JSON.stringify({
                streams: [
                    {
                        stream: { system: "Admin Panel", device: navigator.userAgent, userId: user.id.toString(), tenantId: user.activeTenant?.id.toString() },
                        values: [
                            [`${date.valueOf() * 1000000}`, `${window.location.pathname} ${error?.message}`],
                        ]
                    }
                ]
            })
        }

        fetch('https://logging.apps.parla.tech/loki/api/v1/push', options).then(_ => {
            setDataSending(false);
            setDataSent(true);
        }).catch(() => setDataSending(false))
    }

    function buttonContent() {
        if (sendingData)
            return <i className="fa-spin fa fa-spinner"></i>
        if (dataSent)
            return <span>{t("error.we-will-let-you-know-when-its-fixed")}{<i className="fas fa-check"></i>}</span>;
        return t('error.send-to-parla');
    }

    return <div className='error'>
        <h1>{t("error.sorry-there-was-an-error")}</h1>
        <button className='btn btn-secondary' onClick={() => { setShowData(true); }}>{t("error.view-the-information")}</button>
        {showData ?
            <div className='data'>
                <div className='item'>
                    <label>{t("error.date-and-time")}:</label>
                    <span>{date.toISOString()}</span>
                </div>
                <div className='item'>
                    <label>{t("error.device")}:</label>
                    <span>{navigator.userAgent}</span>
                </div>
                <div className='item'>
                    <label>{t("error.user")}:</label>
                    <span>{user.id} {user.name}</span>
                </div>
                <div className='item'>
                    <label>{t("error.company")}:</label>
                    <span>{user.activeTenant?.id} {user.activeTenant?.name}</span>
                </div>
                <div className='item'>
                    <label>{t("error.path")}:</label>
                    <span className='message'>{window.location.pathname}</span>
                </div>
                <div className='item'>
                    <label>{t("error.message")}:</label>
                    <span className='message'>{error?.message}</span>
                </div>
                <div className='item stack' >
                    <label>{t("error.stack")}:</label>
                    <span>{error?.stack}</span>
                </div>
            </div>
            : null}
        <button className='btn btn-primary' onClick={() => sendLogs()}>{buttonContent()}</button>
    </div>;
}

class ErrorWrapper extends Component<{ children: any }> {
    state = {
        hasError: false,
        error: null
    };

    static getDerivedStateFromError(error: Error) {
        console.error("getDerivedStateFromError:", error);
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    componentDidCatch(error: Error, errorInfo: any) {
        console.error("Uncaught error:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <ErrorResponse error={this.state.error} />
        }
        try {
            return this.props.children;
        }
        catch (error) {
            this.setState({ hasError: true, error });
        }
    }
}

export default ErrorWrapper;
