import { assetsConstants } from "../_constants";

export function assets(state = { modalReference: null, newAssets: [] }, action) {
    switch (action.type) {
        case assetsConstants.ADD_ASSETS_SUCCESS:
            return {
                ...state,
                newAssets: [...state.newAssets, action.asset],
                modalReference: action.modalReference
            };
        case assetsConstants.CLEAR_ASSET:
            return {
                ...state,
                newAssets: []
            }
        default: return state;
    }
}