import common_engb from "./translations/en-GB/common.json";
import common_enus from "./translations/en-US/common.json";
import common_eses from "./translations/es-ES/common.json";
import common_tain from "./translations/ta-IN/common.json";
import i18next from "i18next";


export function loadTranslations(defaultLang: string) {
    console.log("Loading langueage", defaultLang)
    i18next.init({
        interpolation: { escapeValue: false },  // React already does escaping
        lng: defaultLang,                              // language to use
        saveMissing: true,
        fallbackLng: "en-GB",
        resources: {
            "en-GB": { common: common_engb },
            "en-US": { common: common_enus },
            "es-ES": { common: common_eses },
            "ta-IN": { common: common_tain },
        }
    });

    i18next.on('missingKey', (lng, namespace, key, fallbackValue) => {
        console.warn(lng, namespace, key, fallbackValue);
    });
    return i18next;
}

