import { userConstants } from '../_constants';

let user = undefined;
try {
  user = JSON.parse(localStorage.getItem('user'));
} catch (e) {
  //Don't care
  user = undefined;
}
const initialState = user ? { loggingIn: false, loggedIn: true, user: user } : { loggingIn: false, loggedIn: false, user: {} };

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggingIn: false,
        loggedIn: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loggingIn: false
      };
    case userConstants.LOGOUT:
      return {
        loggedIn: false,
        user: {}
      };
    default:
      return state
  }
}