import { createStore, applyMiddleware, Action } from 'redux';
import { thunk, ThunkDispatch } from 'redux-thunk';
import rootReducer from '../_reducers';
import { compose } from 'redux'; // and your other imports from before

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = ThunkDispatch<RootState, unknown, Action>;

export const store = process.env.NODE_ENV === 'development' ?
    createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
    : createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
