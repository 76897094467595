import React, { useEffect } from 'react';
import { InstagramCallback } from './Instagram/InstagramCallback';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { page, pageToPath } from '../_constants';

function Lost() {
    const auth = useSelector(state => state.authentication);
    const loggedIn = auth.loggedIn && auth.user && new Date(auth.user.tokenExpiry) > new Date();
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (!loggedIn) {
            console.log("No Login, Configuring Redirect", location)
            localStorage.setItem("redirect", location.pathname + location.search);
            console.log("Redirecting to Login")
            navigate("/login");
        } else if (!auth.user.activeTenant) {
            console.log("Redirecting to Tenants")
            navigate(pageToPath(page.Tenants));
        } else {
            console.log("Redirecting to Dashboard")
            navigate(pageToPath(page.Dashboard));
        }
    }, []);
    return null;
}

function ThirdPartyPage() {
    return (
        <Routes>
            <Route path="/instagram" element={<InstagramCallback />} />
            <Route path="/*" element={<Lost />} />
        </Routes>
    );
}

export default ThirdPartyPage;