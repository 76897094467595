import { apiService } from './api.service';

export const productGroupService = {
    addGroup,
    deleteGroup,
    searchGroup
}

function addGroup(group) {
    return apiService.addItem("product-groups", group);
}

function deleteGroup(id) {
    return apiService.deleteItem("product-groups", id);
}
function searchGroup(options) {
    return apiService.getItems("product-groups", options);
}
