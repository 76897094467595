import { config } from '../_constants';
import { authHeader, handleResponse } from '../_store';
import { generateOptions } from './options';

export const productTypeService = {
    searchProductTypes,
    addProductType,
    updateProductType,
    deleteProductType,
    getById
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${config.apiUrl}/product-types/${id}?include=fields,image`, requestOptions)
        .then(handleResponse)
}



function searchProductTypes(options) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    var url = `${config.apiUrl}/product-types`;
    if (options) {
        url += generateOptions(url, options);
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(productTypes => { return productTypes })
}

function addProductType(productType) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(productType)
    }
    return fetch(`${config.apiUrl}/product-types`, requestOptions)
        .then(handleResponse)
        .then(productType => { return productType })

}

function updateProductType(productType, errorHandled) {    
    const requestOptions = {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(productType)
    }
    return fetch(`${config.apiUrl}/product-types`, requestOptions)
        .then(response => handleResponse(response, errorHandled))
}

function deleteProductType(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }

    };
    return fetch(`${config.apiUrl}/product-types/${id}`, requestOptions)
        .then(handleResponse);
}