import { config } from '../_constants';
import { authHeader, handleResponse } from '../_store'
import { generateOptions } from './options';
import { apiService } from './api.service';

export const productsService = {
    getProduct,
    getProducts,
    searchProducts,
    deleteProduct,
    updateProduct,
    getProductDetails,
    addProduct,    
    addProductType,
    getProductWithAssets,
    getVariants,
    getProductInventory,
    addInventory,
    updateInventory,
    deleteInventory
}

function addProductType(detail) {
    const obj =
    {
        name: detail.name,
        fields: [
            {
                name: detail.name,
                dataType: detail.dataType,
            }
        ],
        tenantd: 1
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(obj)
    };
    return fetch(`${config.apiUrl}/product-types`, requestOptions)
        .then(handleResponse)
        .then(productType => {
            return productType
        })
}


function getProducts(options) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    let url = `${config.apiUrl}/products`;
    url += generateOptions(url, options);
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(products => {
            return products
        })
}

function getProduct(id, options) {    return apiService.getItems(`products/${id}`, options);}
function getProductDetails(id) { return getProduct(id, {include:['details.field','relatedAssets']});}
function getProductWithAssets(id) { return getProduct(id, {include:['productAssets.asset']});}

function getVariants(id) {
    return apiService.getItems(`products/${id}/variants?include=details.field`);
}

function getProductInventory(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${config.apiUrl}/products/${id}/inventory`, requestOptions)
        .then(handleResponse)
        .then(product => {
            return product
        })
}

function searchProducts(query, options, param) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    const parameter = param ? param : 'name';
    let url = `${config.apiUrl}/products/search?${parameter}=${query}`;
    if (options) {
        if (!options.include)
            options.include = [];
        options.include.push("productAssets.asset", "relatedAssets");
        url += generateOptions(url, options);
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(products => {
            return products
        })
}

function deleteProduct(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }

    };
    return fetch(`${config.apiUrl}/products/${id}`, requestOptions)
        .then(handleResponse);
}
function addProduct(product) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(product)
    }
    return fetch(`${config.apiUrl}/products`, requestOptions)
        .then(handleResponse)
        .then(product => { return product })
}

function updateProduct(product) {
    const cleanedProduct = cleanProductAssets(product);
    const requestOptions = {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(cleanedProduct)
    }
    return fetch(`${config.apiUrl}/products`, requestOptions)
        .then(handleResponse)
        .then(product => { return product })
}
function addInventory(inventory) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(inventory)
    }
    return fetch(`${config.apiUrl}/inventory/`, requestOptions)
        .then(handleResponse)
        .then(product => { console.log(product); return product })
}
function updateInventory(inventory) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(inventory)
    }
    return fetch(`${config.apiUrl}/inventory/`, requestOptions)
        .then(handleResponse)
        .then(product => { return product })
}

function deleteInventory(inventory) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    }
    return fetch(`${config.apiUrl}/inventory/${inventory.id}`, requestOptions)
        .then(handleResponse)
        .then(product => { return product })
}

function cleanProductAssets(product) {
    if (product.productAssets && product.relatedAssets) {
        return {
            ...product,
            productAssets: product.productAssets.map(pa => { return { ...pa, asset: null } }),
            relatedAssets: product.relatedAssets.map(ra => { return { ...ra, asset: null } })
        }
    } else {
        return product;
    }
}