import i18next from "i18next";
import { AppDispatch } from "../../../../_store";

export enum DataType {
    string,
    number,
    date,
    time,
    datetime,
    boolean,
    selection,
    range,
    radio
}

export type Choices = {
    [key: string | number]: string;
}

export interface IFieldData {
    placeholder?: string;
    title?: string;
    element?: (props: ValueProps) => JSX.Element,
    hide?: () => boolean,
    name: string | number | symbol,
    dataType?: DataType
    important?: boolean;
    choices?: Choices;
    min?: number;
    max?: number;
    change?: (value: any) => void;
}

export interface FieldData<T> extends IFieldData {
    name: keyof T,
    hide?: () => boolean,
    change?: (value: any) => void;
}

export interface IValidationError {
    fieldName: string | number | symbol,
    error: string,
}

export interface ValidationError<T> extends IValidationError {
    fieldName: keyof T,
    error: string,
}

export interface ModalFramework<T> {
    data: T;
    title?: string;
    fields: FieldData<T>[];
    save(dispatch: AppDispatch): void;
    validate(save: boolean): Promise<ValidationError<T>[]>;
}

export abstract class ModalFrameworkBase<T> implements ModalFramework<T> {
    title?: string | undefined;
    t = i18next.getFixedT(null, "common");
    abstract data: T;
    abstract fields: FieldData<T>[];
    abstract save(dispatch: AppDispatch): void;
    abstract validate(save: boolean): Promise<ValidationError<T>[]>;
    abstract init(): Promise<ModalFrameworkBase<T>>;

    constructor(updated: () => void) {
        this.updated = updated;
    }

    updated: () => void;

    update(field: keyof T, value: any) {
        console.log("Update", this.data, field, value);
        this.data = { ...this.data, [field]: value }
        this.updated();
    }

    createField(fieldInfo: FieldData<T>): FieldData<T> {
        return { ...fieldInfo, change: (value: any) => { this.update(fieldInfo.name, value); } }
    }
}

export type ValueProps = {
    value: any,
    onChange: (value: any) => void;
}