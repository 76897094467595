import { modalConstants } from "../_constants"

export const LoginModalActions = {
    openModal,
    closeModal
}
function openModal() {
    return dispatch => {
        dispatch({
            type: modalConstants.OPEN_LOGIN_MODAL
        })
    }
}

function closeModal() {
    return dispatch => {
        dispatch({
            type: modalConstants.CLOSE_LOGIN_MODAL,
            isOpen: false
        })
    }
}