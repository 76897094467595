import { apiService } from './api.service';

export const appointmentsService = {
    update,
    add,
}

function update(appointment) {
    return apiService.updateItem("appointment", appointment);
}

function add(appointment) {
    return apiService.addItem("appointment", appointment, true);
}
