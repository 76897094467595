import { config } from '../_constants';
import { authHeader, handleResponse } from '../_store';

export const tagService = {
    searchTags,
    deleteTag,
    updateTag
}

function searchTags(query, page, pageSize) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${config.apiUrl}/tags/search?name=${query}&page=${page}&pagesize=${pageSize}`, requestOptions)
        .then(handleResponse)
        .then(tags => {
            return tags
        })
}

function deleteTag(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }

    };
    return fetch(`${config.apiUrl}/tags/${id}`, requestOptions)
        .then(handleResponse)
        .then(error => { return error });
}

function updateTag(tag) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(tag)
    }
    return fetch(`${config.apiUrl}/tags`, requestOptions)
        .then(handleResponse)
        .then(tag => { return tag })
}