import { breadcrumbConstants } from "../_constants";

export const breadCrumbActions = {
    setBreadCrumbSection,
    unsetBreadCrumbSection,
    clearBreadCrumbSections,
    setBreadCrumbJourney,
    unsetBreadCrumbJourney
}

function setBreadCrumbJourney(journey) {
    return dispatch => {
        dispatch(setParentJourney(journey))
    }
}

function unsetBreadCrumbJourney() {
    return dispatch => {
        dispatch(unsetParentJourney())
    }
}

function setBreadCrumbSection(section) {
    return dispatch => {
        dispatch(setPreviousSection(section))
    }
}

function unsetBreadCrumbSection(section) {
    return dispatch => {
        dispatch(unsetPreviousSection(section))
    }
}

function clearBreadCrumbSections() {
    return dispatch => {
        dispatch(clearSections())
    }
}

function setPreviousSection(section) { return { type: breadcrumbConstants.SET_PREVIOUS_SECTION, section } };
function unsetPreviousSection(section) { return { type: breadcrumbConstants.UNSET_PREVIOUS_SECTION, section } };
function clearSections() { return { type: breadcrumbConstants.CLEAR_SECTIONS } };

function setParentJourney(journey) {
    return { type: breadcrumbConstants.SET_PARENT_JOURNEY, journey }
};
function unsetParentJourney() { return { type: breadcrumbConstants.UNSET_PARENT_JOURNEY } };
