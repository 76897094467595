import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap'; // Import Modal and Button
import { useSelector } from 'react-redux';
import { tenantService } from '../../../_services';
import { accountService } from '../../../_services/accounts.service';
import '../../../Styles/dashboard.scss';
import './accounts.scss';
import { useTranslation } from 'react-i18next';
import PricingModal from './PricingModal';
import { isAdmin } from '../../../_helpers/user.perms';

export default function AccountsPlan() {
    const [usage, setUsage] = useState();
    const [plan, setPlan] = useState();
    const [account, setAccount] = useState();

    useEffect(() => {
        tenantService.getAccountUsage().then((data) => setUsage(data));
    }, []);

    useEffect(() => {
        if (account) {
            console.log("Got the account", account);
            setPlan(account.servicePlan);
        }
    }, [account]);

    useEffect(() => {
        if (usage) {
            console.log("Got the usage", usage);
            accountService.getAccount(usage.id, ['servicePlan']).then(setAccount);
        }
    }, [usage]);
    return <AccountsUsage account={account} plan={plan} limits={usage?.limits} remaining={usage?.remaining} />
}

export function AccountsUsage(props) {

    const user = useSelector(state => state.authentication.user);
    const { account, plan, limits, remaining } = props;
    const { t } = useTranslation("common");

    useEffect(() => {
        if (account) {
            FirstUse();
        }
    }, [account])

    const [showModal, setShowModal] = useState(false);

    function handleShow() {
        setShowModal(true);
    }

    function handleClose() {
        setShowModal(false);
    }

    function FirstUse() {
        if (account.newAccount) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        var negative = false;
        if (bytes < 0) {
            negative = true;
            bytes *= -1;
        }
        console.log("Format Bytes", bytes);
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        let numberFormatter = new Intl.NumberFormat(window.navigator.languages[0], { style: 'decimal' });
        return (negative ? "-" : "") + numberFormatter.format(parseFloat((bytes / Math.pow(k, i)).toFixed(dm))) + ' ' + sizes[i];
    }

    function UsageItem({ title, count, max, suffix = true, formatter, empty, icon }) {
        const loadingImg = <img
            width={15}
            height={15}
            alt="Loading spinner"
            src="/loading.gif"
        />;
        console.log("Name:", title, "Max:", max, "Count:", count);
        const value = formatter && max ? formatter(max - count) : formatter ? formatter(count) : max ? max - count : count;
        const total = formatter && max ? formatter(max) : formatter ? 0 : max ? max : 0;
        return (
            <div className="usage account">
                {icon && <div className='icon-wrapper'>
                    {icon}
                </div>}
                <div>
                    <label className="usage-title">{t("account-plan." + title)}</label>
                    {!empty && <div className="usage-value">
                        {!max && count !== undefined && <span>{t("account-plan.remaining", { count: value, title: suffix ? t("account-plan." + title) : "" })}</span>}
                        {max && count !== undefined && <span>{t("account-plan.used-of", { max: total, used: value, title: suffix ? t("account-plan." + title) : "" })}</span>}
                        {count === undefined && loadingImg}
                    </div>}
                    {empty && <div className="usage-value"></div>}
                </div>
            </div>
        );
    }

    return (
        <div className='card-holder col-12 h-100'>
            <Card className='h-100'>
                <div className="dashboard-card-sm-title">{t("account.plan-details")}</div>
                {isAdmin(user) &&
                    <div className='free-plan'>
                        <div>
                            <span>{t("account-plan.current-plan")}</span>
                            <h5>{plan?.name}</h5>
                        </div>
                        {plan && plan.isStandardPlan ?
                            <button onClick={handleShow}>
                                {t("account-plan.change-plan")}
                            </button>
                            : null}
                    </div>}

                <Card.Body>
                    <div className="plan-counter justify-content-center justify-content-md-start">
                        <UsageItem title="users" icon={<i class="fas fa-user"></i>} count={remaining ? remaining.users : null} max={limits ? limits.users : null} />
                        <UsageItem title="minutes" icon={<i class="fas fa-stopwatch"></i>} count={remaining ? remaining.minutes : null} max={limits ? limits.minutes : null} formatter={(v) => v?.toFixed(0)} />
                        <UsageItem title="appointments" icon={<i className="fas fa-calendar-alt"></i>} count={remaining ? remaining.appointments : null} max={limits ? limits.appointments : null} />
                        <UsageItem title="assets" icon={<i class="fas fa-images"></i>} count={remaining ? remaining.assets : null} max={limits ? limits.assets : null} />
                        <UsageItem title="storage" icon={<i class="fas fa-cloud-upload"></i>} count={remaining ? remaining.assetbytes : null} max={limits ? limits.assetbytes : null} formatter={formatBytes} suffix={false} />
                        {plan && !plan.isStandardPlan &&
                            <UsageItem title="journeys" icon={<i class="fas fa-directions"></i>} count={remaining ? remaining.journeys : null} max={limits ? limits.journeys : null} />
                        }
                    </div>
                </Card.Body>
            </Card>
            {isAdmin(user) && <PricingModal showModal={showModal} handleClose={handleClose} />}
        </div>
    );
}
