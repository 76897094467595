import { assetsService } from "../_services";
import { productTypeService } from "../_services/product-types.service";
import { alertActions } from "./alert.actions";
import { tableActions } from "./table.actions";

export const productTypeActions = {
    addProductType,
    saveProductTypeUpdate,
    deleteProductType,
    saveProductTypeUpdateWithPhoto
}

function addProductType(productType) {
    return dispatch => {
        productTypeService.addProductType(productType)
            .then(
                productType => {
                    dispatch(tableActions.addToTable(productType));
                    dispatch(alertActions.success(`${productType.name} successfully created`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}

function deleteProductType(id) {
    return dispatch => {
        productTypeService.deleteProductType(id)
            .then(
                () => {
                    dispatch(alertActions.success(`Successfully deleted productType ${id}`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}

function saveProductTypeUpdate(productType) {
    return dispatch => {
        productTypeService.updateProductType(productType, true)
            .then(
                productType => {
                    dispatch(alertActions.success(`Successfully updated ${productType.name}`));
                },
                error => {                    
                    dispatch(alertActions.error(error.message.toString()));
                }
            )
    }
}
function saveProductTypeUpdateWithPhoto(productType, file, setProductType) {
    return dispatch => {
        assetsService.uploadFile({file:file}).then(
            img => {
                let temp = { ...productType, image: img, imageId: img.id };
                setProductType(temp)
                dispatch(saveProductTypeUpdate(temp))
            })

    }
}