import { auditsConstants } from "../_constants/audit.constants";

export function audits(state = {}, action) {
    switch (action.type) {
        case auditsConstants.AUDITS_GETALL_REQUEST:
            return {
                ...state
            };
        case auditsConstants.AUDITS_GETALL_FAILURE:
            return {
                error: action.error
            };
        case auditsConstants.AUDITS_GETALL_SUCCESS:
            return {
                ...state,
                items: action.audits.items,
                rows: action.audits.count
            };
        default:
            return state
    }
}