import { twilioContsants } from '../_constants/twilio.constants';

export function twilio(state = { livestream: false }, action) {
    switch (action.type) {
        case twilioContsants.START_LIVESTREAM:
            return {
                ...state, livestream: true
            };
        case twilioContsants.END_LIVESTREAM:
            return {
                ...state, livestream: false
            };
        default:
            return state
    }
}