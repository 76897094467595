

import React from 'react'
import { useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { customerService } from '../../_services/customer.service';
import ImageComponent from '../Layout/ImageComponent';
import '../../Styles/_components/customerSelector.scss';
import { Button } from 'react-bootstrap';
import { tenantService } from '../../_services';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CustomerModal } from '../Modals/Add/CustomerModal';
import { FieldInput } from '../Modals/Add/Framework/FieldInput';

export default function CustomerSelector({ important, customerId, customerSelected }) {
    const { t } = useTranslation("common")
    const [availableCustomers, setAvailableCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [framework, setFramework] = useState();
    const [validationErrors, setValidationErrors] = useState([]);
    const [newCustomerData, setNewCustomerData] = useState();
    const [tenantPrivateCustomers, setTenantPrivateCustomers] = useState(false);
    const user = useSelector(state => state.authentication.user);
    const [errorMessage, setErrorMessage] = useState();

    useEffect(() => {
        if (customerId && !availableCustomers.length) {
            fetchCustomer(customerId)
        }
    }, [customerId])

    useEffect(() => {
        tenantService.getTenantById(user.activeTenant.id, 'options').then(t => setTenantPrivateCustomers(t.options?.allowPrivateCustomers))
        const frameworkPromise = new CustomerModal(updated).init();
        async function updated() {
            if (frameworkPromise) {
                const framework = await frameworkPromise;
                const validationErrors = await framework.validate(false);
                setNewCustomerData(framework.data);
                setValidationErrors(validationErrors);
            } else {
                console.log("No Modal Framework");
            }
        }
        if (frameworkPromise) {
            frameworkPromise.then(setFramework);
        }
    }, [])

    const mapToMultiSelect = (item) => {
        return {
            value: item.id,
            label: item.name,
            item: item
        }
    }

    const handleCustomerSelection = (selected) => {
        if (selected && selected.length) {
            console.log("Customer Selected", selected)
            let newestAddition = selected[selected.length - 1]
            setSelectedCustomer([newestAddition]);
            customerSelected(newestAddition.value);
        } else {
            setSelectedCustomer([]);
            customerSelected(undefined);
        }
    }

    async function loadOptions(options, typedIn) {
        var results = await customerService.search({
            searchFields: ['name', '|email', '|phone'],
            searchValues: [typedIn, typedIn, typedIn],
            include: ['photo']
        });
        return results.items.map(mapToMultiSelect);
    }

    function fetchCustomer(id) {
        customerService.getById(id, { include: ["photo"] }).then(result => {
            console.log(result)
            if (result) {
                var customers = [mapToMultiSelect(result)];
                setAvailableCustomers(customers);
                setSelectedCustomer(customers);
            }
        });
    }

    function valueRenderer(selectedCustomer) {
        return <span>
            {renderCustomer({ option: selectedCustomer[0] })}
        </span>
    }

    function renderCustomer(props) {
        const { option, onClick } = props;
        if (option)
            return <div className="dropdown-customer" onClick={onClick}>
                <ImageComponent assetId={option.item.photo} width={30} height={30} />
                <div className="dropdown-customer-details">
                    <label className='dropdown-customer-name'>{option.label}</label>
                    <label className='dropdown-customer-email'>{option.item.email}</label>
                    <label className='dropdown-customer-phone'>{option.item.phone}</label>
                </div>
            </div>
        else
            return <div>{t("editingComponent.customerSelector.select-a-customer")}...</div>
    }

    function createNewCustomer() {

        if (tenantPrivateCustomers) {
            newCustomerData.isPrivate = true;
            newCustomerData.salesAssociateId = user.id;
        }

        customerService.add(newCustomerData).then(newCustomer => {
            if (newCustomer) {
                setAvailableCustomers(customers => [...customers, mapToMultiSelect(newCustomer)]);
                setSelectedCustomer([mapToMultiSelect(newCustomer)]);
                customerSelected(newCustomer.id);
                setShowAddModal(false);
            } else {
                setErrorMessage(t("editingComponent.customerSelector.email-address-already-exists"));
            }
        })
    }

    return (
        <div className="form-group">
            <div className='form-header'>
                {important ? <label className="col-form-label"><span className="text-danger">*</span>{t("editingComponent.customerSelector.customer")}</label>
                    : <label className="col-form-label">{t("editingComponent.customerSelector.customer")}</label>}
                <div>
                    <a className='add-customer' onClick={() => { setShowAddModal(true) }}><i className="fas fa-users-medical"></i> {t("editingComponent.customerSelector.add-customer")}</a>
                </div>
            </div>
            <div>
                <MultiSelect
                    hasSelectAll={false}
                    options={availableCustomers}
                    filterOptions={loadOptions}
                    value={selectedCustomer}
                    valueRenderer={valueRenderer}
                    ItemRenderer={renderCustomer}
                    onChange={selected => handleCustomerSelection(selected)}
                    closeOnChangedValue={true}
                />
            </div>
            {showAddModal ? <div className='customer-modal-wrapper' onClick={() => { setShowAddModal(false) }}>
                <div className='customer-modal' onClick={(e) => { e.stopPropagation() }}>
                    <h4>{t("editingComponent.customerSelector.create-a-new-customer")}</h4>
                    {framework.fields.map((f, i) => {
                        return <div key={i.toString()} className='field'>
                            <FieldInput field={f} framework={framework} />
                            {validationErrors.filter(v => v.fieldName == f.name).map((v, i) => <span key={i} className='validation-error'>{v.error}</span>)}
                        </div>
                    })}
                    <Button disabled={validationErrors.length} onClick={() => { createNewCustomer() }}>{t("editingComponent.customerSelector.add-customer")}</Button>
                    {errorMessage ? <div className='text-danger'>{errorMessage}</div> : null}
                </div>
            </div> : null}
        </div>
    )
}
