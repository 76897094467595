import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import '../../../Styles/dashboard.scss';
import { apiService } from "../../../_services/api.service";
import { assetsService } from '../../../_services';
import { useTranslation } from 'react-i18next';
import { journeyService } from "../../../_services/journeys.service";
import { DateTime } from 'luxon';
import { State, User } from '../../../_types';

type Rating = {
    userId: number,
    rank: number,
    rating: number,
    response: number,
}

export default function PerformanceTrackerCard() {
    const [view, setView] = useState('rating');
    const [userRatings, setUserRatings] = useState<Rating[]>([]);
    const [me, setMe] = useState<Rating>();
    const [overallSatisfaction, setOverallSatisfaction] = useState(0);
    const user = useSelector<State, User>(state => state.authentication.user);
    const currentUserId = user.id;
    const currentUserPhotoId = user.assetId;
    const username = user.name;
    const [userPhoto, setUserPhoto] = useState<string>('');
    const { t } = useTranslation("common");

    useEffect(() => {
        if (user) {
            getAllUserRatings();
        }
    }, [user]);

    useEffect(() => {
        if (me) {
            setOverallSatisfaction(me.response === 0 ? me.rating : (me.rating + me.response) / 2);
        }
    }, [me]);

    useEffect(() => {
        if (currentUserPhotoId) {
            getAssetContent(currentUserPhotoId).then(setUserPhoto)
        }
    }, [currentUserPhotoId])

    type Ratings = {
        top10: Rating[],
        me: Rating
    }

    async function getAllUserRatings() {
        const allUsers: Ratings = await apiService.getItems(`users/${user.id}/rating?start=2001-01-01&end=2025-01-01`);
        setUserRatings(allUsers.top10 || []);
        setMe(allUsers.me);
    }

    async function getAssetContent(assetId: number) {
        if (assetId) {
            const response = await assetsService.getAssetById(assetId);
            return response ? response.content : null; // Return asset content if available
        }
        return null;
    }

    const filledStars = Math.floor(overallSatisfaction);
    const halfStar = overallSatisfaction % 1 >= 0.5;

    const renderStars = () => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= filledStars) {
                stars.push(<i key={i} className="fas fa-star filled"></i>); // filled star
            } else if (i === filledStars + 1 && halfStar) {
                stars.push(<i key={i} className="fas fa-star-half-alt filled"></i>); // half-filled star
            } else {
                stars.push(<i key={i} className="far fa-star"></i>); // empty star
            }
        }
        return stars;
    };

    const maxScore = 5;
    const customerFeedbackPercentage = (me?.rating ?? 0 / maxScore) * 100;
    const responseAvrPercentage = (me?.response ?? 0 / maxScore) * 100;
    const overallSatisfactionPercentage = (overallSatisfaction / maxScore) * 100;

    return (
        <div className='card-holder h-100'>
            <Card className='dashboard-appointments-card h-100'>
                <div className='dashboard-card-sm-title'>{t("dashboard.performance-tracker")}</div>
                <div className='dashboard-card-tags-wrap'>
                    <div className={`dashboard-card-tag ${view === 'rating' ? 'active' : ''}`} onClick={() => setView('rating')}>
                        {t("dashboard.rating")}
                    </div>
                    <div className={`dashboard-card-tag ${view === 'ranking' ? 'active' : ''}`} onClick={() => setView('ranking')}>
                        {t("dashboard.ranking")}
                    </div>
                </div>
                <Card.Body>
                    {view === 'rating' ? (
                        <div className='rating-card-wrap'>
                            <div className='rating-card'>
                                <div className='rating-value'>
                                    <span className='number'>{overallSatisfaction.toFixed(2)}</span>
                                    <span className='stars'>
                                        {renderStars()}
                                    </span>
                                </div>
                            </div>
                            <div className='rating-communication-value'>
                                <div className='rating-communication-title'>
                                    <span>{t("dashboard.customer-feedback")}</span>
                                    <span>{me?.rating.toFixed(2)}</span>
                                </div>
                                <div className='rating-response-wrap'>
                                    <span className='rating-response-foreground' style={{ width: `${customerFeedbackPercentage}%` }} ></span>
                                    <span className='rating-communication-background'></span>
                                </div>
                            </div>
                            <div className='rating-response-value'>
                                <div className='rating-communication-title'>
                                    <span>{t("dashboard.response-average")}</span>
                                    <span>{me?.response === 0 ? '-' : me?.response.toFixed(2)}</span>
                                </div>
                                <div className='rating-response-wrap'>
                                    <span className='rating-communication-foreground' style={{ width: `${responseAvrPercentage}%` }}></span>
                                    <span className='rating-communication-background'></span>
                                </div>
                            </div>
                            <div className='rating-overall-value'>
                                <div className='rating-communication-title'>
                                    <span>{t("dashboard.overall-satisfaction")}</span>
                                    <span>{overallSatisfaction.toFixed(2)}</span>
                                </div>
                                <div className='rating-response-wrap'>
                                    <span className='rating-overall-foreground' style={{ width: `${overallSatisfactionPercentage}%` }}></span>
                                    <span className='rating-communication-background'></span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='ranking-user-list'>
                            {userRatings.map((user, index) => (
                                <RankedUser user={user} currentUserId={currentUserId} rank={user.rank} userPhoto={userPhoto} username={username} />
                            ))}
                            {!userRatings.some(u => u.userId == currentUserId) && me && <RankedUser user={me} currentUserId={currentUserId} rank={me.rank} userPhoto={userPhoto} username={username} />}
                        </div>
                    )}
                </Card.Body>
            </Card>
        </div>
    );
}

type RankedUserProps = {
    user: Rating, currentUserId: number, rank: number, userPhoto: string, username: string
}

function RankedUser(props: RankedUserProps) {

    const { user, currentUserId, rank, userPhoto, username } = props;

    return <div className={user.userId === currentUserId ? "ranking-user self-user" : "ranking-user"} key={user.userId}>
        <span className='ranking-number'>#{rank}</span>
        <span className='ranking-user-wrap'>
            <span className='ranking-user-image'>
                {userPhoto && user.userId === currentUserId ? <img src={userPhoto} className="ranking-profile-picture" />
                    : <i className="fas fa-user-circle"></i>}
            </span>
            <span className='ranking-user-name'>{user.userId === currentUserId ? username : "Anonymous"}</span>
            <span className='ranking-user-rating'>
                <i className='fas fa-star'></i>
                {(user.response > 0 ? ((user.rating + user.response) / 2) : user.rating).toFixed(2)}
            </span>
        </span>
    </div>
}