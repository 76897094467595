import uploadFileTypes from '../_constants/uploadFile.constants';

export const setUploadFile = (files, asset, modalReference) => ({
    type: uploadFileTypes.SET_UPLOAD_FILE,
    payload: { files, asset, modalReference },
})

export const setUploadProgress = (id, progress) => ({
    type: uploadFileTypes.SET_UPLOAD_PROGRESS,
    payload: {
        id,
        progress,
    },
})

export const successUploadFile = id => ({
    type: uploadFileTypes.SUCCESS_UPLOAD_FILE,
    payload: id,
})

export const failureUploadFile = id => ({
    type: uploadFileTypes.FAILURE_UPLOAD_FILE,
    payload: id,
})

export const removeCompletedFile = id => ({
    type: uploadFileTypes.REMOVE_COMPLETED_FILE,
    payload: id,
})
