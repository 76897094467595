import React, { useState, useEffect, useRef} from "react";

function ImageComponent({ asset, width, height, className = "", altText = "" }) {
    const [failed, setFailed] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [imageWidth, setWidth] = useState(null);
    const [imageHeight, setHeight] = useState(null);

    useEffect(() => {
        if (width) {
            setWidth(width)
        }
        if (height) {
            setHeight(height)
        }
    }, [width, height])


    function PickASize({ className, altText }) {
        if (asset.sizes && asset.sizes.some(s => s.width == 50) && (width <= 50 && height <= 50)) {
            return <img className={className} onLoad={() => setLoaded(true)} onError={() => setFailed(true)} alt={altText} src={asset.content + '/50x50'} width={width} height={height} />
        } else if (asset.sizes && asset.sizes.some(s => s.width == 600) && (width <= 600 || height <= 600)) {
            return <img className={className} onLoad={() => setLoaded(true)} onError={() => setFailed(true)} alt={altText} src={asset.content + '/600x600'} width={width} height={height} />
        } else if (asset.sizes && asset.sizes.some(s => s.width == 1200) && (width <= 1200 || height <= 1200)) {
            return <img className={className} onLoad={() => setLoaded(true)} onError={() => setFailed(true)} alt={altText} src={asset.content + '/1200x1200'} width={width} height={height} />
        } else if (asset.sizes && asset.sizes.some(s => s.width == 2400) && (width <= 2400 || height <= 2400)) {
            return <img className={className} onLoad={() => setLoaded(true)} onError={() => setFailed(true)} alt={altText} src={asset.content + '/2400x2400'} width={width} height={height} />
        } else {
            return <img className={className} onLoad={() => setLoaded(true)} onError={() => setFailed(true)} alt={altText} src={asset.content} width={width} height={height} />
        }
    }
    const styleObj = {
        width: imageWidth,
        height: imageHeight
    }

    let nameOfClass = "asset";
    let noAsset = "no-asset";
    let quote = "card-quote";
    let loadingSpinner = "loadingSpinner";
    if (className) {
        nameOfClass += " " + className;
        noAsset += " " + className;
        quote += " " + className;
        loadingSpinner += " " + className;
    }

    if (failed) {
        return (
            <div className={"asset-parent-wrapper"} style={styleObj} >
                <div className="failed-asset">
                    <img
                        className={nameOfClass}
                        width={imageWidth}
                        height={imageHeight}
                        alt="Image failed"
                        src="https://svgsilh.com/svg/2026753.svg"
                    />
                </div>
            </div>
        )
    } else {
        if (!asset)
            return <div className={noAsset} width={imageWidth} height={imageHeight} ></div>
        switch (asset.contentType) {
            case 'text/plain': return <label className={quote}>{asset.content}</label>;
            case 'image/bmp':
            case 'image/gif':
            case 'image/jpg':
            case 'image/jpeg':
            case 'image/svg+xml':
            case "image/png": return (
                <div className="asset-parent-wrapper" style={styleObj} >
                    <PickASize className={`${nameOfClass}${!loaded ? " d-none" : ""}`} altText={altText ?? ""} />
                    {!loaded &&
                        <img
                            className={loadingSpinner}
                            width={imageWidth}
                            height={imageHeight}
                            alt="Loading spinner"
                            src="/loading.gif"
                        />
                    }
                </div>);
            case 'iframe/vimeo':
                return <iframe className={nameOfClass} src={asset.content} width={imageWidth} height={imageHeight} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
            case 'iframe/youtube':
                return <iframe className={nameOfClass} src={asset.content} frameBorder="0" width={imageWidth} height={imageHeight} ></iframe>
            case 'video/mp4': return <video className={nameOfClass} autoPlay muted defaultmuted="true" loop id="video-asset" width={imageWidth} height={imageHeight}><source src={asset.content} type="video/mp4" /></video>;
            case 'video/quicktime': return <video className={nameOfClass} autoPlay muted defaultmuted="true" loop id="video-asset" width={imageWidth} height={imageHeight}><source src={asset.content} type="video/mp4" /></video>;
            case 'audio/mp3':
            case 'audio/aiff':
            case 'audio/mpeg':
            case 'audio/m4a': return (<AudioPlayer asset={asset} />)
            default: return null
        }
    }
}

export default ImageComponent;

export const AudioPlayer = ({ asset }) => {
    const ref = useRef();
    const [duration, setDuration] = useState(0);
    const [time, setTime] = useState(0);
    const [paused, setPaused] = useState(true);

    function togglePlay(e) {
        e.preventDefault();
        if (ref.current) {
            if (ref.current.paused) {
                ref.current.play();
            } else {
                ref.current.pause();
            }
            setPaused(ref.current.paused)
        }
    }

    useEffect(() => {
        if (ref.current) {
            const cachedRef = ref.current;
            if (cachedRef) {
                const updateDuration = () => setDuration(cachedRef.duration.toFixed(1))
                cachedRef.addEventListener('loadedmetadata', updateDuration);
                setDuration(cachedRef.duration.toFixed(1));
                const interval = setInterval(() => { setTime(cachedRef.currentTime.toFixed(1)) }, 100);
                return () => {
                    cachedRef.removeEventListener('loadedmetadata', updateDuration)
                    clearInterval(interval)
                }
            }
        }
    }, [ref.current])

    return (
        <div className="audio-element">
            <div>
                <button className="btn btn-primary" onClick={(e) => togglePlay(e)}><i className={paused ? 'fas fa-play' : 'fas fa-pause'}></i></button>
                <label>{time} / {duration}</label>
            </div>
            <audio ref={ref} controls={false} >
                <source src={asset.content} type={asset.contentType}></source>
            </audio>
        </div>
    )
}