import { helpPanelConstants } from "../_constants/helpPanel.constants";

const initialState = {
    isOpen: false,
    panelReference: ''
};

export function helpPanel(state = initialState, action) {
    switch (action.type) {
        case helpPanelConstants.OPEN_PANEL:
            return {
                ...state,
                isOpen: true,
                panelReference: action.panelReference
            };
        case helpPanelConstants.CLOSE_PANEL:
            return {
                ...state,
                isOpen: false
            };
        case helpPanelConstants.CHANGE_PANEL_OPTION:
            return {
                ...state,
                panelReference: action.panelReference
            };

        default: return state;
    }
}