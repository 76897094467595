import { config } from "../../_constants";
import { generateOptions } from "../../_services/options";
import { authHeader, handleResponse } from "../../_store";


export const tableService = {
    getItems,
    deleteItem,
    getSortedItems,
    searchItems,
    getItemById
}
const HEADER = () => {
    return {
        ...authHeader(),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
}

function getItems(options) {
    const requestOptions = {
        method: 'GET',
        headers: { ...HEADER() }
    };
    let url = `${config.apiUrl}/${options.path}`;
    if (options) {
        url += generateOptions(url, options);
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result
        })
}

function getItemById(options) {
    const requestOptions = {
        method: 'GET',
        headers: { ...HEADER() }
    };
    return fetch(`${config.apiUrl}/${options.path}/${options.id}`, requestOptions)
        .then(handleResponse)
        .then(result => { return result })
}

function searchItems(path, options) {
    const requestOptions = {
        method: 'GET',
        headers: { ...HEADER() }
    };
    var url = `${config.apiUrl}/${path}`;
    if (options) {
        url += generateOptions(url, options);
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(response => { return response })
}

function getSortedItems(options) {
    const requestOptions = {
        method: 'GET',
        headers: { ...HEADER() }
    };
    var url = `${config.apiUrl}/${options.path}`;
    if (options) {
        url += generateOptions(url, options);
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(response => { return response })
}

function deleteItem(options) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...HEADER() }
    };
    return fetch(`${config.apiUrl}/${options.path}/${options.id}`, requestOptions)
        .then((response) => handleResponse(response, true))
}