import { useEffect, useState } from "react";
import { config } from '../../_constants';
import { authHeader } from "../../_store";
import { generateOptions } from "../../_services/options";
import "./loader.scss"

type UseLoaderProps = {
    path: string;
    options: {
        searchFields: string[];
        searchValues: string[];
        pageSize: number;
        include: string[];
    }
}

export function useLoader({ path, options }: UseLoaderProps) {
    const [progress, setProgress] = useState(10);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [response, setResponse] = useState(null);
    const [reload, setReload] = useState<boolean>(true);

    const fetchData = () => {
        setLoading(true);
        setProgress(0);
        setReload(false)

        let url = `${config.apiUrl}/${path}${options ? generateOptions(config.apiUrl, options) : ""}`;
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        const headers: any = {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }

        for (const key in headers) {
            if (Object.prototype.hasOwnProperty.call(headers, key)) {
                xhr.setRequestHeader(key, headers[key]);
            }
        }

        xhr.onprogress = (event) => {
            if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;
                setProgress(percentComplete);
            }
        };

        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                const result = JSON.parse(xhr.responseText);
                setResponse(result);

            } else {
                setError(new Error('Network response was not ok'));
            }
            setProgress(100);
            setLoading(false);
        };

        xhr.onerror = () => {
            setError(new Error('Network error'));
            setLoading(false);
        };

        xhr.send();
    };

    useEffect(() => {
        if (reload)
            fetchData();
    }, [reload]);

    const circumference = 2 * Math.PI * 45;
    const offset = circumference - (progress / 100) * circumference;
    const Loader = () => <div className="relative w-40 h-40 loader-wrapper">
        <div className="loader-container">
            <svg className="loader" viewBox="0 0 100 100">
                <circle className="loader-bg" cx="50" cy="50" r="45"></circle>
                <circle className="loader-fg" cx="50" cy="50" r="45" style={{ strokeDashoffset: offset }}></circle>
            </svg>
            <div className="percentage">{Math.floor(progress)}%</div>
        </div>
    </div>
    return {
        error,
        response,
        Loader,
        loading,
        progress,
        reload: setReload
    }
}