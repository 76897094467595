import { config } from '../_constants';
import { authHeader, handleResponse } from '../_store';

export const integrationsService = {
    getIntegrations

}

function getIntegrations(page, pageSize) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }
    }
    return fetch(`${config.apiUrl}/integrations?page=${page}&pagesize=${pageSize}&include=logo`, requestOptions)
        .then(handleResponse)
        .then(integrations => {
            return integrations.items
        })
}