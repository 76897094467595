import React from 'react';
import { Provider } from 'react-redux';
import { store } from './_store';
import { App } from './App';
import { I18nextProvider } from "react-i18next";
import { loadTranslations } from "./translation"
import { createRoot } from 'react-dom/client';
import { languageList } from './Components/Layout/LanguageSelector';
const i18next = loadTranslations(getLang());

function getLang() {
  const language = navigator.languages ? navigator.languages[0] : navigator.language;
  if (languageList.some(l => l.value == language)) {
    return language;
  }
  return "en-GB"
}

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </Provider>,

);