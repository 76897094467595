import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { LoginPage } from '../Pages/LoginPage/LoginWrapper';
import ThirdPartyPage from '../ThirdParty/ThirdPartyPage';
import { useSelector } from 'react-redux';
import { LoggedInAdminPanel } from './LoggedInAdminPanel';
import { AuthPage } from '../Pages/Auth';
import ErrorWrapper from './ErrorWrapper';
import { AuthenticationState, State } from '../_types';

const loggedInRoutes = createBrowserRouter([
    { path: "/auth", element: <ErrorWrapper><AuthPage /></ErrorWrapper> },
    { path: "/switch", element: <></> },
    { path: "/*", element: <ErrorWrapper><LoggedInAdminPanel /></ErrorWrapper> }
]);

const loggedOutRoutes = createBrowserRouter([
    { path: "/login", element: <ErrorWrapper><LoginPage /> </ErrorWrapper> },
    { path: "/reset-password", element: <ErrorWrapper><LoginPage section="password" /> </ErrorWrapper> },
    { path: "/signup", element: <ErrorWrapper><LoginPage section="signup" /> </ErrorWrapper> },  // New Signup Route
    { path: "/:page/*", element: <ErrorWrapper><ThirdPartyPage /> </ErrorWrapper> },
    { path: "/*", element: <ErrorWrapper><LoginPage /></ErrorWrapper> }
]
);

export function Router() {
    const auth = useSelector<State, AuthenticationState>((state) => state.authentication);
    const loggedIn = auth.loggedIn && auth.user && new Date(auth.user.tokenExpiry) > new Date();
    console.log("Checking Login", auth, loggedIn)
    return (
        <RouterProvider router={loggedIn ? loggedInRoutes : loggedOutRoutes} />
    );
}