import React from 'react';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { apiService } from '../_services/api.service';
import { useSelector } from 'react-redux';
import '../Styles/_components/auth.scss';
import TenantsPage from './TenantsPage';
import { useTranslation } from 'react-i18next';

export function AuthPage() {
    const { t } = useTranslation("common");
    //Need Intgeration...ID?
    //Requested Permissions?
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    console.log(params);
    const permissions = parsePermissions(params.get('permissions'));
    const integrationId = params.get('integration');
    const state = params.get('state');
    const [integration, setIntegration] = useState();
    const [integrationToken, setIntegrationToken] = useState();
    const user = useSelector(state => state.authentication.user);
    console.log(user);
    if (!user.activeTenant) {
        console.log("No Login (Missing active tenant), Configuring Redirect", location)
        return <TenantsPage redirect={location} />
    }
    const subdomain = user.activeTenant.subdomain;
    const tenantId = user.activeTenant.id;
    console.log(subdomain);
    useEffect(() => {
        apiService.getItem("integrations", integrationId).then(setIntegration);
    }, [integrationId]);

    function parsePermissions(permissionSet) {
        let result = [];
        if (permissionSet) {
            const permissions = permissionSet.split(',');
            for (const permission of permissions) {
                const [target, accessLevel] = permission.split('.');
                result.push({ target: target, accessLevel: accessLevel })
            }
        }
        return result;
    }

    function createToken() {
        apiService.addItem("integrations/createToken", { id: integration.id, permissions: permissions })
            .then(tokenResult => {
                setIntegrationToken(tokenResult.token);
            });
    }

    if (integrationToken && integration) {
        console.log("Authorised");
        //This is insecure, don't send the integrationToken through the iFrame send an OTP instead and use that to retreive the integrationToken
        window.location.href = `${integration.createUri}?s=${subdomain}&t=${tenantId}&a=${integrationToken}&state=${state}`
        return null;
    }

    function translateAccessLevel(accessLevel) {
        var permissions = [];
        if (accessLevel & 1) { permissions.push(1); }
        if (accessLevel & 2) { permissions.push(2); }
        if (accessLevel & 4) { permissions.push(4); }
        if (accessLevel & 8) { permissions.push(8); }
        if (accessLevel & 16) { permissions.push(16); }
        if (accessLevel & 32) { permissions.push(32); }
        return permissions.map(permission => {
            switch (permission) {
                case 1: return <div>{t("auth.read-property")}</div>
                case 2: return <div>{t("auth.read-single")}</div>
                case 4: return <div>{t("auth.list")}</div>
                case 8: return <div>{t("auth.write")}</div>
                case 16: return <div>{t("auth.create")}</div>
                case 32: return <div>{t("auth.delete")}</div>
            }
        }
        )

    }
    return (
        <div className='permission-panel'>
            <div className='permission-panel-inner-wrap'>
                {permissions.map((permission, i) => <div key={i} className='permission-row'><div className='permission-target'>{permission.target}</div><div className='permission-level'>{translateAccessLevel(permission.accessLevel)}</div></div>)}
                <div className='confirmation-modal-buttons'>
                    <button className='btn-confirm' onClick={() => { createToken() }}>{t("auth.approve")}</button>
                    <button className='default-btn'>{t("auth.deny")}</button>
                </div>
            </div>

        </div>)
}