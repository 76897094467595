import { fulfilmentService } from "../_services/fulfilments.service";
import { alertActions } from "./alert.actions";
import { tableActions } from "./table.actions";

export const fulfilmentActions = {
    addFulfilment,
    saveFulfilmentUpdate,
    deleteFulfilment
}

function addFulfilment(fulfilment) {
    return dispatch => {
        fulfilmentService.addFulfilment(fulfilment)
            .then(
                fulfilment => {
                    dispatch(tableActions.addToTable(fulfilment));
                    dispatch(alertActions.success(`${fulfilment.name} successfully created`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}

function deleteFulfilment(id) {
    return dispatch => {
        fulfilmentService.deleteFulfilment(id)
            .then(
                () => {
                    dispatch(alertActions.success(`Successfully deleted fulfilment ${id}`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}

function saveFulfilmentUpdate(fulfilment) {
    return dispatch => {
        fulfilmentService.updateFulfilment(fulfilment)
            .then(
                fulfilment => {
                    dispatch(alertActions.success(`Successfully updated ${fulfilment.name}`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }
}