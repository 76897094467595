import { useTranslation } from "react-i18next";
import { InputElement } from "./InputElement";
import { IFieldData, ModalFramework } from "./ModalTypes";

type FieldInputProps = {
    field: IFieldData,
    submitted: boolean,
    framework: ModalFramework<any>
}


export function FieldInput(props: FieldInputProps) {
    const { field, submitted, framework } = props;
    const { t } = useTranslation('common');

    function changed(val: any) {
        if (field.change) {
            field.change(val)
        }
    }

    if (field.hide && field.hide())
        return null;

    return <div className={"form-group mt-2"}>
        {field.important ?
            <label>
                <span className="text-danger">*</span>{field.title ? t("modals.field." + field.title) : t("modals.field." + field.name.toString())}
            </label>
            :
            <label>
                {field.title ? t("modals.field." + field.title) : t("modals.field." + field.name.toString())}
            </label>
        }
        <InputElement field={field} submitted={submitted} value={framework.data[field.name]} setValue={(val) => changed(val)} />
    </div>
}