import { config, page, pageToUrl } from "../_constants"
import { authHeader, handleResponse } from "../_store"
import { apiService } from "./api.service"

export const orderService = {
    addOrder,
    saveOrderUpdate,
    deleteOrder,
    verify,
    getById,
    searchOrders,
    sendConsignment,
    linkOrderToSession
}

const url = pageToUrl(page.Orders)

function verify(handler, orderId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetch(`${config.apiUrlThirdParty}/${handler}/verify/${orderId}`, requestOptions)
        .then(response => {
            if (response.status === 404) 
                return null;
            return handleResponse(response)
        })
}
function addOrder(order) {
    return apiService.addItem(url, order)
}
function saveOrderUpdate(order) {
    return apiService.updateItem(url, order)
}
function deleteOrder(order) {
    return apiService.deleteItem(url, order.id)
}
function getById(id) {
    return apiService.getItem(url, id, { include: ['deliveryAddress', 'seller', 'customer.photo', 'orderParts.deliveryService', 'orderParts.items.product.productAssets.asset'] })
}

function sendConsignment(orderId) {
    return apiService.addItem(`${url}/dispatch/${orderId}`)
}

function searchOrders(options) {
    return apiService.getItems(url, options)
}

function linkOrderToSession(update) {
    return apiService.addItem(`${url}/session`, update)
}
