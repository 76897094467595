import { modalConstants } from "../_constants"

export const editModalActions = {
    openModal,
    closeModal,
    closeEmbeddedModal,
    openEmbeddedModal
}
function openModal(modalObject) {
    return dispatch => {
        dispatch({
            type: modalConstants.OPEN_EDIT_MODAL,
            modalObject
        })
    }
}

function closeModal() {
    return dispatch => {
        dispatch({
            type: modalConstants.CLOSE_EDIT_MODAL,
            isOpen: false
        })
    }
}
function openEmbeddedModal(modalObject) {
    return dispatch => {
        dispatch({
            type: modalConstants.OPEN_EMBEDDED_MODAL,
            isOpen: true,
            modalObject
        })
    }
}

function closeEmbeddedModal() {
    return dispatch => {
        dispatch({
            type: modalConstants.CLOSE_EMBEDDED_MODAL,
            isOpen: false
        })
    }
}