import { config } from '../_constants';
import { authHeader, handleResponse } from '../_store';
import { apiService } from './api.service';
import { generateOptions } from './options';

export const fulfilmentService = {
    searchFulfilments,
    addFulfilment,
    updateFulfilment,
    deleteFulfilment,
    getById
}

function getById(id, options) {
    return apiService.getItem('fulfilment', id, options).then(fulfilments => fulfilments)
}

function searchFulfilments(options) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    var url = `${config.apiUrl}/fulfilment`;
    if (options) {
        url += generateOptions(url, options);
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(fulfilments => { return fulfilments })
}

function addFulfilment(fulfilment) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(fulfilment)
    }
    return fetch(`${config.apiUrl}/fulfilment`, requestOptions)
        .then(handleResponse)
        .then(fulfilment => { return fulfilment })

}

function updateFulfilment(fulfilment) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(fulfilment)
    }
    return fetch(`${config.apiUrl}/fulfilment`, requestOptions)
        .then(handleResponse)
        .then(fulfilment => { return fulfilment })
}

function deleteFulfilment(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }

    };
    return fetch(`${config.apiUrl}/fulfilment/${id}`, requestOptions)
        .then(handleResponse);
}
