import React, { useEffect, useState } from 'react';
import { apiService, tenantService, userService } from '../../_services';
import Toggle from 'react-toggle';
import { Availability } from '../../_enums/Availability';
import { useTranslation } from 'react-i18next';
import { shopLocationService } from '../../_services/shop-locations.service';
import { Feature } from '../../_types';

export function DashboardAvailability(props) {
    const { t } = useTranslation("common");
    const { user } = props;

    const [chatAvailable, setChatAvailable] = useState(false);
    const [callsAvailable, setCallsAvailable] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [ctaEnabled, setCtaEnabled] = useState(false);
    const [activeTipIndex, setActiveTipIndex] = useState(0);
    const [features, setFeatures] = useState([]);

    useEffect(() => {
        tenantService.getFeatures().then(setFeatures);
    }, []);

    const tips = [
        {
            id: 1,
            content: t("dashboard.tip-1")
        },
        {
            id: 2,
            content: t("dashboard.tip-2")
        },
    ];

    useEffect(() => {
        getAvailability(user.id);
        const tenantId = user.activeTenant.id;
        tenantService.getTenantById(tenantId, 'activeIntegrations').then(t => {
            if (t.activeIntegrations.some(ai => ai.class == 'CTA')) {
                setCtaEnabled(true);
            }
        });
    }, [user]);

    async function getAvailability(id) {
        const departments = await apiService.getItem(`users/${id}/availability`, undefined)
        const deps = []
        if (departments) {
            for (let i = 0; i < departments.length; i++) {
                const element = departments[i];
                deps.push({ ...element, shop: await shopLocationService.getByid(element.department.shopId) })
            }
            setDepartments(deps);
            setAllAvailable(departments, Availability.Chat, setChatAvailable);
            setAllAvailable(departments, Availability.Video, setCallsAvailable);
        }
    }

    function setAllAvailable(departments = [], availability, setter) {
        if (departments.length > 0) {
            for (const dep of departments) {
                if (!(dep.available & availability)) {
                    setter(false);
                    return;
                }
            }
            setter(true);
        }
    }

    function setAvailability(departmentId, availability, group) {
        setDepartments(d => {
            const changed = d.findIndex(d => d.id == departmentId);
            if (changed != -1) {
                d[changed].available = availability;
            }
            const update = {
                departments: d.map(d1 => ({ id: d1.id, available: d1.available }))
            };
            userService.updateTenantData(user.id, update)
            if (group === "chat") {
                const filtered = d.filter(({ available }) => available === 1 || available === 3);
                setChatAvailable(filtered.length > 0 && filtered.length === d.length);
            }
            if (group === "calls") {
                const filtered = d.filter(({ available }) => available === 2 || available === 3)
                setCallsAvailable(filtered.length > 0 && filtered.length === d.length);
            }
            return [...d];
        });
    }

    async function setGroupAvailable(available, availability, setAvai) {
        setAvai(available);

        const updatedDepartments = departments.map(department => ({
            ...department,  // Preserve the entire department object
            available: available
                ? department.available | availability  // Enable the specified availability
                : department.available & ~availability  // Disable the specified availability
        }));

        setDepartments(updatedDepartments);

        const update = {
            departments: updatedDepartments.map(dep => ({
                id: dep.id,
                available: dep.available
            }))
        };

        // Update the server with the new availability for all departments
        await userService.updateTenantData(user.id, update);
    };

    async function setAwayOnCloseTab(event) {
        event.preventDefault();
        const update = {
            departments: departments.map((dep) => ({ id: dep.id, available: 0 }))
        };
        await userService.updateTenantData(user.id, update);
    }

    useEffect(() => {
        window.addEventListener("beforeunload", setAwayOnCloseTab);
        return () => window.removeEventListener("beforeunload", setAwayOnCloseTab);
    }, [departments])

    return (
        <div className="col-12 h-100">
            {ctaEnabled ? (
                <div className='card h-100'>
                    <div className='availability'>
                        <div>
                            <div className='dashboard-card-sm-title'>{t("dashboard.communication-controls")}
                                <div className='availability-cta-wrap'>
                                    {features && features.includes(Feature.Message) ?
                                        <div className='availability-cta'>
                                            <span>{t("dashboard.enable-all-chats")}</span>
                                            <Toggle checked={chatAvailable} onChange={() => setGroupAvailable(!chatAvailable, Availability.Chat, setChatAvailable)} />
                                        </div>
                                        : null}
                                    <div className='availability-cta'>
                                        <span>{t("dashboard.enable-all-calls")}</span>
                                        <Toggle checked={callsAvailable} onChange={() => setGroupAvailable(!callsAvailable, Availability.Video, setCallsAvailable)} />
                                    </div>
                                </div>
                            </div>
                            <div className='departments-availability-table'>
                                <div className='departments-availability-table-header'>
                                    <span className='col-1-shop'>{t("dashboard.shop")}</span>
                                    <span className='col-2-dept'>{t("dashboard.department")}</span>
                                    {features && features.includes(Feature.Message) ? <span className='col-3-chat'>{t("dashboard.chat")}</span> : null}
                                    <span className='col-4-call'>{t("dashboard.call")}</span>
                                </div>
                                <div className='department-availability-wrap'>
                                    {departments.map((d, i) => (
                                        <div key={i} className='department-availability'>
                                            <span className='col-1-shop'>{d.shop?.name}</span>
                                            <span className='col-2-dept'> {d.department && d.department.name ? d.department.name : t("dashboard.loading-department")}</span>
                                            {features && features.includes(Feature.Message) ? <span className='col-3-chat'><Toggle checked={d.available & Availability.Chat ? true : false} onChange={() => setAvailability(d.id, d.available ^ Availability.Chat, 'chat')} /></span> : null}
                                            <span className='col-4-call'><Toggle checked={d.available & Availability.Video ? true : false} onChange={() => setAvailability(d.id, d.available ^ Availability.Video, 'calls')} /></span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='card h-100'>
                    <div className='pro-tips'>
                        <div className='pro-tips-title'>
                            <i className="fas fa-quote-left"></i>
                            <span>{t("dashboard.pro-tip")}:</span>
                        </div>
                        <div className='pro-tips-body'>
                            {tips[activeTipIndex].content}
                        </div>
                        <div className='pro-tips-buttons'>
                            {tips.map((tip, index) => (
                                <a
                                    key={tip.id}
                                    className={activeTipIndex === index ? 'active' : ''}
                                    onClick={() => setActiveTipIndex(index)}
                                >
                                    <i className="fas fa-circle"></i>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
